import React, { useState, ReactNode, useContext } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import { TableColumn, TBodyCssClasses } from 'interfaces/randomShapes';
import s from './CollapsibleRow.module.scss';
import cn from 'classnames';
import { ErrorRowContext, StickyContext } from '../../../../constants/privilegesConstants';

interface CollapsibleRowProps {
  row: { id: number; isCollapsible?: boolean; profile_title?: string; isLostPosition?: boolean };
  columns: Array<TableColumn>;
  getContent: (
    colId: string,
    row,
    collapseCallback?: () => void,
    isCollapseOpen?: boolean,
    isCollapsible?: boolean
  ) => ReactNode;
  getCollapsibleContent: (colId, row: Record<string, unknown>) => ReactNode;
  classes?: TBodyCssClasses;
}

const CollapsibleRow = ({
  row,
  columns,
  getContent,
  getCollapsibleContent,
  classes
}: CollapsibleRowProps): JSX.Element => {
  const [isOpen, setOpen] = useState(false);
  const onToggleCollapse = () => setOpen((prev) => !prev);
  const { leftSticky, rightSticky, bottomSticky, rightStickySecond, rightStickyThird } = useContext(StickyContext);
  const { errorKey } = useContext(ErrorRowContext);
  const isErrorRow = !!row?.[errorKey];
  return (
    <TableRow classes={{ root: cn(s.collapsibleRoot, s.over, { [s.rowWithError]: isErrorRow }) }}>
      {columns.map(({ id, align }: TableColumn) => {
        return (
          <TableCell
            key={id}
            align={align}
            classes={{
              root: cn({
                root: s.collapsibleCellRoot,
                [classes?.tBodyCellClass]: classes?.tBodyCellClass,
                [s.stickiedLeft]: id === leftSticky,
                [s.stickiedSingleRight]: !rightStickyThird && rightStickySecond ? false : id === rightSticky,
                [s.stickiedRight]: !rightStickyThird && rightStickySecond ? id === rightSticky : false,
                [s.rightStickySecond]: !rightStickyThird && id === rightStickySecond,
                [s.stickiedBottom]: row?.[bottomSticky?.key] ? row?.[bottomSticky?.key] === bottomSticky?.val : false,
                [s.rightStickyThird]: rightStickyThird === id,
                [s.rightStickyThird2]: rightStickySecond === id,
                [s.rightStickyThird3]: rightSticky === id
              })
            }}
          >
            {getContent(id, row, onToggleCollapse, isOpen, row?.isCollapsible)}
            <Collapse in={isOpen} timeout="auto" unmountOnExit>
              <div className={s.collapseContentWrapper}>{getCollapsibleContent?.(id, row)}</div>
            </Collapse>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default CollapsibleRow;
