import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import s from './AccountStatsTable.module.scss';
import Button from '@material-ui/core/Button';
import NavTabs from '../navTabs/NavTabs';
import CustomizedTable from '../customizedTable/CustomizedTable';
import { HARD_PER_PAGE } from '../../constants/randomConstants';
import {
  ACCOUNT_STATS_COLUMNS,
  AccountStatsColIds,
  DEFAULT_ACCOUNT_STATS_PARAMS,
  initialAccountStatsValues,
  InitialAccountStatsValuesType
} from '../../constants/accountStatsConstants';
import { useDispatch, useSelector } from 'react-redux';
import { getAccountStats, getAccountStatsExport, getAccountStatsFilters } from '../../redux/actions/statsActions';
import { accountStatsSelector, profileStateFilters } from '../../redux/selectors';
import { AdminTableRow } from '../../interfaces/signalsOrdersShapes';
import { accountStatsRenderSwitch } from '../../helpers/accountStatsHelpers';
import { ErrorShape } from '../../interfaces/reduxRandomShapes';
import { getErrorCutMessageHelper, getErrorMessageHelper } from '../../helpers/randomHelpers';
import AccountStatsFilters from './filters/AccountStatsFilters';
import { removeEmptyStatsParams } from '../../helpers/emptyParamsRemoverHelpers';
import { StickyContext } from '../../constants/privilegesConstants';
import { usePrivileges } from '../../hooks/usePrivileges';
import { getStatsTabs, isSectionFull, isSectionReadOnly } from '../../helpers/privilegesHelpers';

const AccountStatsTable: FC = () => {
  const dispatch = useDispatch();

  const [filterParams, setFilterParams] = useState<InitialAccountStatsValuesType>(initialAccountStatsValues);
  const [disabledFilter, setDisabledFilter] = useState(true);
  const [reqParams, setReqParams] = useState(DEFAULT_ACCOUNT_STATS_PARAMS);

  const [isLoading, setLoading] = useState(false);
  const onToggleLoading = () => setLoading((prev) => !prev);

  const [reqError, setReqErr] = useState<null | string>(null);
  const onSetError = (err: ErrorShape) => {
    const errorMsg = getErrorMessageHelper(err);
    if (errorMsg) {
      setReqErr(getErrorCutMessageHelper(errorMsg));
    }
  };

  const onGetAccountStats = useCallback(() => {
    onToggleLoading();
    dispatch(getAccountStats(removeEmptyStatsParams(reqParams), onToggleLoading, onSetError));
  }, [dispatch, reqParams]);

  useEffect(onGetAccountStats, [dispatch, onGetAccountStats]);

  useEffect(() => {
    dispatch(getAccountStatsFilters());
  }, [dispatch]);

  const accountStats = useSelector(accountStatsSelector);
  const { profiles } = useSelector(profileStateFilters);

  const getContent = (colId, row: AdminTableRow) => {
    return accountStatsRenderSwitch(colId, row);
  };

  const [isLoadingExport, setLoadingExport] = useState(false);
  const onToggleExportLoading = () => setLoadingExport((prev) => !prev);

  const onExport = useCallback(() => {
    onToggleExportLoading();
    dispatch(getAccountStatsExport(removeEmptyStatsParams(reqParams), onToggleExportLoading));
  }, [dispatch, reqParams]);

  const privileges = usePrivileges();
  const tabs = getStatsTabs(privileges);

  const isExportDisabled = useMemo(() => {
    return isSectionFull(privileges.account_stats_export) ? isLoadingExport : true;
  }, [isLoadingExport, privileges.account_stats_export]);

  return (
    <div className={s.tableWrapper}>
      <div className={s.headerWrapper}>
        <h2 className="default-title">Account stats</h2>
        <Button
          variant="outlined"
          size="medium"
          type="button"
          classes={{ root: s.exportBtn }}
          onClick={onExport}
          disabled={isExportDisabled}
        >
          Export
        </Button>
        <NavTabs tabs={tabs} classes={{ wrapperClass: s.navTabWrapper }} />
      </div>
      {!isSectionReadOnly(privileges.account_stats) && (
        <AccountStatsFilters
          filterParams={filterParams}
          disabledFilter={disabledFilter}
          setDisabledFilter={setDisabledFilter}
          reqParams={reqParams}
          setReqParams={setReqParams}
          profiles={profiles}
          setFilterParams={setFilterParams}
        />
      )}
      <StickyContext.Provider value={{ leftSticky: AccountStatsColIds.DIR }}>
        <CustomizedTable
          count={accountStats.length}
          columns={ACCOUNT_STATS_COLUMNS}
          rows={accountStats}
          isLoading={isLoading}
          error={reqError}
          emptyRowsMsg="There are no account stats yet..."
          getContent={getContent}
          getCollapsibleContent={() => null}
          rowsPerPageOptions={[HARD_PER_PAGE]}
          customPerPage={HARD_PER_PAGE}
          customPage={0}
          onCustomChangePage={() => null}
          offPerPage
          classes={{
            tableWrapper: s.tableWrapper,
            wrapperClass: s.signalsContent,
            tHeadClasses: {
              tHeadCellClass: s.tHeadCellClass
            },
            tBodyClasses: {
              tBodyCellClass: s.tBodyCellClass
            }
          }}
        />
      </StickyContext.Provider>
    </div>
  );
};

export default AccountStatsTable;
