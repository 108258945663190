import React, { ReactNode } from 'react';
import { MonthsTotal, SingleResultRow } from '../interfaces/monthsResultsShapes';
import { ColumnAlignOptions } from '../constants/randomConstants';
import {
  COL_DATE_FORMAT,
  FixationMonthMap,
  HiddenTotalValue,
  HideColoredTooltips,
  MonthsResultKeys,
  MonthsResultMap,
  MonthsStatsTableColIds,
  OrderMonthMap,
  RowColors,
  SignMonthMap
} from '../constants/monthsStatsConstants';
import { TableColumn } from '../interfaces/randomShapes';
import { formatISODate } from './randomHelpers';
import s from 'components/monthsStatsTable/MonthsStatsTable.module.scss';
import CustomTooltip from '../components/customTooltip/CustomTooltip';

export const getMonthsStatsColumns = (date: string, index: number): TableColumn => ({
  id: index.toString(),
  title: formatISODate(date, COL_DATE_FORMAT),
  align: ColumnAlignOptions.CENTER
});

export const createMonthsStatsRowsFlexible = (
  accTitle: string,
  key: string,
  summery: MonthsTotal,
  values: string[],
  addedOrder: number
): SingleResultRow => {
  return {
    account: accTitle,
    data_type: key,
    res_by_months: values,
    total: summery.total,
    average: summery.avg_by_months,
    average_days: summery.avg_by_days,
    order: OrderMonthMap[key] + addedOrder
  };
};

const getResultColor = (value: number) => {
  switch (true) {
    case value >= 15:
      return s.high;
    case value >= 8 && value < 15:
      return s.middleHigh;
    case value >= 5 && value < 8:
      return s.middle;
    case value >= 0 && value < 5:
      return s.middleLow;
    case value < 0:
      return s.low;
    default:
      return s.low;
  }
};

export const monthsStatsRenderSwitch = (
  colId: MonthsStatsTableColIds,
  row: SingleResultRow,
  tooltip: string
): ReactNode => {
  const value = row[colId];
  switch (colId) {
    case MonthsStatsTableColIds.ACCOUNT:
      return <>{value}</>;

    case MonthsStatsTableColIds.DATA_TYPE:
      return <>{MonthsResultMap[value] ?? value}</>;

    case MonthsStatsTableColIds.AVERAGE:
    case MonthsStatsTableColIds.AVERAGE_DAYS:
      if (row.data_type === MonthsResultKeys.DEPOSIT_MONTHLY || value === null) {
        return <>-</>;
      }

      return (
        <>
          {Number(value).toFixed(FixationMonthMap[row.data_type])}
          {SignMonthMap[row.data_type]}
        </>
      );

    case MonthsStatsTableColIds.TOTAL:
      if (HiddenTotalValue.includes(row.data_type as MonthsResultKeys)) {
        return <>-</>;
      }

      return (
        <>
          {Number(value).toFixed(FixationMonthMap[row.data_type])}
          {SignMonthMap[row.data_type]}
        </>
      );

    default:
      if (!HideColoredTooltips.includes(row.data_type as MonthsResultKeys)) {
        return (
          <span>
            {row.res_by_months[colId]}
            {SignMonthMap[row.data_type]}
          </span>
        );
      }

      return (
        <CustomTooltip
          title={
            <div>
              {RowColors.map((color) => (
                <div className={s.currentColorContainer} key={color.title}>
                  <div className={color.colorClass} />
                  <div>{color.title}</div>
                </div>
              ))}
            </div>
          }
          className={tooltip}
        >
          <div className={getResultColor(+row.res_by_months[colId])}>
            <span>
              {row.res_by_months[colId]}
              {SignMonthMap[row.data_type]}
            </span>
          </div>
        </CustomTooltip>
      );
  }
};
