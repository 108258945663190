import { ColumnAlignOptions } from './randomConstants';
import s from '../components/monthsStatsTable/MonthsStatsTable.module.scss';

export const COL_DATE_FORMAT = 'MMM yy';

export enum MonthsStatsTableColIds {
  ACCOUNT = 'account',
  DATA_TYPE = 'data_type',
  TOTAL = 'total',
  AVERAGE = 'average',
  AVERAGE_DAYS = 'average_days'
}

export enum MonthsStatsFilterIDs {
  DATE_FROM = 'date_from',
  DATE_TO = 'date_to'
}

export const MonthsStatsLabels = {
  [MonthsStatsFilterIDs.DATE_FROM]: 'From',
  [MonthsStatsFilterIDs.DATE_TO]: 'To'
};

export const MONTHS_STAT_DATE = [
  {
    id: MonthsStatsFilterIDs.DATE_FROM,
    label: MonthsStatsLabels[MonthsStatsFilterIDs.DATE_FROM],
    fieldName: MonthsStatsFilterIDs.DATE_FROM,
    value: ''
  },
  {
    id: MonthsStatsFilterIDs.DATE_TO,
    label: MonthsStatsLabels[MonthsStatsFilterIDs.DATE_TO],
    fieldName: MonthsStatsFilterIDs.DATE_TO,
    value: MonthsStatsFilterIDs.DATE_TO
  }
];

const MONTHS_STATS_COL_NAMES = {
  [MonthsStatsTableColIds.ACCOUNT]: 'Account',
  [MonthsStatsTableColIds.DATA_TYPE]: 'Data type',
  [MonthsStatsTableColIds.AVERAGE_DAYS]: 'Avg.Days',
  [MonthsStatsTableColIds.AVERAGE]: 'Avg.Months',
  [MonthsStatsTableColIds.TOTAL]: 'Total'
};

export const MONTHS_STATS_COLUMNS_START = [
  {
    id: MonthsStatsTableColIds.DATA_TYPE,
    title: MONTHS_STATS_COL_NAMES[MonthsStatsTableColIds.DATA_TYPE],
    align: ColumnAlignOptions.CENTER,
    width: '100px'
  }
];

export const MONTHS_STATS_COLUMNS_END = [
  {
    id: MonthsStatsTableColIds.AVERAGE_DAYS,
    title: MONTHS_STATS_COL_NAMES[MonthsStatsTableColIds.AVERAGE_DAYS],
    align: ColumnAlignOptions.CENTER,
    width: '40px'
  },
  {
    id: MonthsStatsTableColIds.AVERAGE,
    title: MONTHS_STATS_COL_NAMES[MonthsStatsTableColIds.AVERAGE],
    align: ColumnAlignOptions.CENTER,
    width: '40px'
  },
  {
    id: MonthsStatsTableColIds.TOTAL,
    title: MONTHS_STATS_COL_NAMES[MonthsStatsTableColIds.TOTAL],
    align: ColumnAlignOptions.CENTER,
    width: '40px'
  }
];

export enum MonthsResultKeys {
  RES_BY_MONTHS = 'res_by_months',
  Q_SIGNALS = 'q_signals',
  Q_PROFITS = 'q_profits',
  Q_LOSSES = 'q_losses',
  W_PERCENT = 'w_percent',
  PF = 'pf',
  RESULT_USD = 'result_usd',
  TURN_OVER = 'turn_overs',
  DEPOSIT_MONTHLY = 'deposit_monthly',
  RES_BY_MONTHS_DIFF = 'res_by_months_diff'
}

export const MonthsResultMap = {
  [MonthsResultKeys.RES_BY_MONTHS]: 'Months Res.',
  [MonthsResultKeys.Q_SIGNALS]: 'Q Signals',
  [MonthsResultKeys.Q_PROFITS]: 'Q Profits',
  [MonthsResultKeys.Q_LOSSES]: 'Q Losses',
  [MonthsResultKeys.W_PERCENT]: 'W Percent',
  [MonthsResultKeys.PF]: 'PF',
  [MonthsResultKeys.RESULT_USD]: 'Result in USD',
  [MonthsResultKeys.TURN_OVER]: 'Turnover',
  [MonthsResultKeys.DEPOSIT_MONTHLY]: 'High Water Mark',
  [MonthsResultKeys.RES_BY_MONTHS_DIFF]: 'Re-invest'
};

export const RowColors = [
  { title: '<0(%)', colorClass: s.low },
  { title: '0-5(%)', colorClass: s.lowMid },
  { title: '5-8(%)', colorClass: s.mid },
  { title: '8-15(%)', colorClass: s.midHigh },
  { title: '>15(%)', colorClass: s.high }
];

export const SignMonthMap = {
  [MonthsResultKeys.RES_BY_MONTHS]: '%',
  [MonthsResultKeys.Q_SIGNALS]: '',
  [MonthsResultKeys.Q_PROFITS]: '',
  [MonthsResultKeys.Q_LOSSES]: '',
  [MonthsResultKeys.W_PERCENT]: '%',
  [MonthsResultKeys.PF]: '',
  [MonthsResultKeys.RESULT_USD]: '$',
  [MonthsResultKeys.TURN_OVER]: '',
  [MonthsResultKeys.DEPOSIT_MONTHLY]: '$',
  [MonthsResultKeys.RES_BY_MONTHS_DIFF]: '%'
};

export const FixationMonthMap = {
  [MonthsResultKeys.RES_BY_MONTHS]: 2,
  [MonthsResultKeys.Q_SIGNALS]: 0,
  [MonthsResultKeys.Q_PROFITS]: 0,
  [MonthsResultKeys.Q_LOSSES]: 0,
  [MonthsResultKeys.W_PERCENT]: 2,
  [MonthsResultKeys.PF]: 0,
  [MonthsResultKeys.RESULT_USD]: 2,
  [MonthsResultKeys.TURN_OVER]: 0,
  [MonthsResultKeys.DEPOSIT_MONTHLY]: 0,
  [MonthsResultKeys.RES_BY_MONTHS_DIFF]: 2
};

export const OrderMonthMap = {
  [MonthsResultKeys.DEPOSIT_MONTHLY]: 1,
  [MonthsResultKeys.RES_BY_MONTHS]: 2,
  [MonthsResultKeys.RES_BY_MONTHS_DIFF]: 3,
  [MonthsResultKeys.RESULT_USD]: 4,
  [MonthsResultKeys.Q_SIGNALS]: 5,
  [MonthsResultKeys.Q_PROFITS]: 6,
  [MonthsResultKeys.Q_LOSSES]: 7,
  [MonthsResultKeys.W_PERCENT]: 8,
  [MonthsResultKeys.PF]: 9,
  [MonthsResultKeys.TURN_OVER]: 10
};

export const HiddenTotalValue = [MonthsResultKeys.PF, MonthsResultKeys.W_PERCENT, MonthsResultKeys.DEPOSIT_MONTHLY];
export const HideColoredTooltips = [MonthsResultKeys.RES_BY_MONTHS, MonthsResultKeys.RES_BY_MONTHS_DIFF];
