import React, { FC } from 'react';
import s from './MonthStatsFilters.module.scss';
import { Button, Divider, Grid, Paper, Popover } from '@material-ui/core';
import { ButtonColorTypes } from '../../../constants/randomConstants';
import { GetStatsByMonthQuery } from 'interfaces/monthsResultsShapes';
import CalendarMonthIcon from '@material-ui/icons/DateRange';
import YearMonthSelect from './YearMonthSelect';

interface MonthsStatsFiltersProps {
  reqParams: GetStatsByMonthQuery;
  setReqParams: (params: GetStatsByMonthQuery) => void;
  filterParams: GetStatsByMonthQuery;
  setFilterParams: (params: GetStatsByMonthQuery) => void;
  startDate: string;
}

const MonthStatsFilters: FC<MonthsStatsFiltersProps> = ({ setReqParams, reqParams, startDate }) => {
  const [open, setOpen] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [params, setParams] = React.useState<GetStatsByMonthQuery>({});
  const [buttonLabel, setButtonLabel] = React.useState('');

  const toggleModal = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen((prev) => !prev);
  };

  const closeModal = () => {
    setOpen(false);
  };

  const getDateAndSubtractMonths = (months: number) => {
    const currentDate = new Date();
    currentDate.setDate(1);

    const subtractedDate = new Date(currentDate);
    subtractedDate.setMonth(subtractedDate.getMonth() - (months - 1));

    const formatDateString = function (date: Date) {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const day = date.getDate().toString().padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    return {
      date_to: formatDateString(currentDate),
      date_from: formatDateString(subtractedDate)
    };
  };

  const handleGetLastYear = () => {
    const currentDate = new Date();
    const lastYear = currentDate.getFullYear() - 1;

    const date_to = `${lastYear}-12-01`;
    const date_from = `${lastYear}-01-01`;

    setButtonLabel(`Last year`);
    setReqParams({ date_to, date_from });
    setOpen(false);
  };

  const handleGetThisYear = () => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const date_from = `${currentYear}-01-01`;
    const date_to = `${currentYear}-${currentMonth.toString().padStart(2, '0')}-${'01'.padStart(2, '0')}`;

    setButtonLabel(`This year`);
    setReqParams({ date_to, date_from });
    setOpen(false);
  };

  const handleButtonFilter = (range: number) => {
    const dates = getDateAndSubtractMonths(range);
    setButtonLabel(`Last ${range} months`);
    setReqParams(dates);
    setOpen(false);
  };

  const resetButtonFilter = () => {
    setButtonLabel('All time');
    setReqParams({});
    setOpen(false);
  };

  const handleFilterChange = (data: GetStatsByMonthQuery) => {
    setParams({ ...params, ...data });
  };

  const applyFilters = () => {
    setButtonLabel(`${params.date_from ?? ''}  ${params.date_to ?? ''}`);
    setReqParams(params);
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="outlined"
        endIcon={<CalendarMonthIcon />}
        onClick={toggleModal}
        classes={{ root: s.rangeButton }}
      >
        {buttonLabel || 'Pick Date Range'}
      </Button>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={closeModal}
        anchorOrigin={{
          vertical: 'center',
          horizontal: 'right'
        }}
      >
        <Paper className={s.filterWrapper}>
          <Grid container>
            <YearMonthSelect
              onChange={(data) => handleFilterChange(data)}
              initialFromDate={reqParams.date_from}
              initialToDate={reqParams.date_to}
              startDate={startDate}
            />

            <Button
              fullWidth
              variant="contained"
              size="medium"
              type="button"
              color={ButtonColorTypes.PRIMARY}
              classes={{ root: s.filterButton }}
              onClick={() => applyFilters()}
              disabled={!Object.keys(params).length}
            >
              Apply
            </Button>
            <Divider />
            <Button
              fullWidth
              variant="outlined"
              size="medium"
              type="button"
              color={ButtonColorTypes.DEFAULT}
              classes={{ root: s.filterButton }}
              onClick={() => handleGetThisYear()}
            >
              This Year
            </Button>
            <Button
              fullWidth
              variant="outlined"
              size="medium"
              type="button"
              color={ButtonColorTypes.DEFAULT}
              classes={{ root: s.filterButton }}
              onClick={() => handleGetLastYear()}
            >
              Last Year
            </Button>
            <Button
              fullWidth
              variant="outlined"
              size="medium"
              type="button"
              color={ButtonColorTypes.DEFAULT}
              classes={{ root: s.filterButton }}
              onClick={() => handleButtonFilter(12)}
            >
              Last 12 Months
            </Button>
            <Button
              fullWidth
              variant="outlined"
              size="medium"
              type="button"
              color={ButtonColorTypes.DEFAULT}
              classes={{ root: s.filterButton }}
              onClick={() => handleButtonFilter(6)}
            >
              Last 6 Months
            </Button>
            <Button
              fullWidth
              variant="outlined"
              size="medium"
              type="button"
              color={ButtonColorTypes.DEFAULT}
              classes={{ root: s.filterButton }}
              onClick={() => handleButtonFilter(3)}
            >
              Last 3 Months
            </Button>
            <Button
              fullWidth
              variant="outlined"
              size="medium"
              type="button"
              color={ButtonColorTypes.DEFAULT}
              onClick={() => resetButtonFilter()}
            >
              All Time
            </Button>
          </Grid>
        </Paper>
      </Popover>
    </div>
  );
};

export default MonthStatsFilters;
