import React, { useContext, useRef, useState } from 'react';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { SwapVert, ArrowDownward, ArrowUpward } from '@material-ui/icons';
import { TableColumn, THeadCssClasses } from 'interfaces/randomShapes';
import cn from 'classnames';
import s from '../CustomizedTable.module.scss';
import { makeStyles } from '@material-ui/core';
import { StickyContext } from '../../../constants/privilegesConstants';

interface THeadProps {
  hasSortingOption: boolean;
  columns: TableColumn[];
  classes?: THeadCssClasses;
  sortHandler: (columnName?: string, withMinusSign?: boolean) => void;
  getCustomTitle: () => JSX.Element;
}

const useStyles = makeStyles({
  leftSticky: {
    position: 'sticky',
    left: 0,
    zIndex: 13
  },
  rightSingleSticky: {
    position: 'sticky',
    right: 0,
    zIndex: 13
  },
  rightSticky: {
    position: 'sticky',
    right: '47px',
    zIndex: 13
  },
  rightStickySecond: {
    position: 'sticky',
    right: 0,
    zIndex: 13
  },
  rightStickyThird3: {
    position: 'sticky',
    right: '120px',
    zIndex: 13
  },
  rightStickyThird2: {
    position: 'sticky',
    right: '60px',
    zIndex: 13
  },
  rightStickyThird: {
    position: 'sticky',
    right: 0,
    zIndex: 13
  }
});

const THead: React.FC<THeadProps> = ({ columns, classes, sortHandler, hasSortingOption, getCustomTitle }) => {
  const sortingColumn = useRef('');
  const [sortAscending, setSortAscending] = useState(true);

  const sortingHandler = (title: string) => {
    if (sortingColumn.current === title) {
      // если направление сортировки отрицательно меняем на дефолтное состояние
      if (!sortAscending) {
        sortingColumn.current = '';
      }

      // если текущий столбец уже отсортирован, меняем направление сортировки
      setSortAscending(false);
    } else {
      // если сортируем другой столбец, сбрасываем направление на возрастание
      setSortAscending(true);
      sortingColumn.current = title;
    }

    sortHandler(sortingColumn.current, sortAscending);
  };

  const getSortingIcon = (column: TableColumn) => {
    if (column.title === sortingColumn.current) {
      return sortAscending ? <ArrowUpward /> : <ArrowDownward />;
    }
    return <SwapVert />;
  };

  const { leftSticky, rightSticky, rightStickySecond, rightStickyThird } = useContext(StickyContext);
  const muiClasses = useStyles();

  return (
    <TableHead>
      <TableRow>
        {columns.map((column, idx, arr) => {
          return (
            <TableCell
              key={column.id}
              align={column.align}
              style={{ width: column.width ?? `calc(100% / ${arr.length})` }}
              className={cn(s.tHeadCell, {
                [classes?.tHeadCellClass]: classes?.tHeadCellClass,
                [muiClasses.leftSticky]: column.id === leftSticky,
                [muiClasses.rightSingleSticky]: !rightStickyThird && !rightStickySecond && column.id === rightSticky,
                [muiClasses.rightSticky]: !rightStickyThird && rightStickySecond ? column.id === rightSticky : false,
                [muiClasses.rightStickySecond]: !rightStickyThird && column.id === rightStickySecond,
                [muiClasses.rightStickyThird]: rightStickyThird && column.id === rightSticky,
                [muiClasses.rightStickyThird2]: rightStickyThird && column.id === rightStickySecond,
                [muiClasses.rightStickyThird3]: rightStickyThird && column.id === rightStickyThird
              })}
            >
              <div
                className={s.sortWrapper}
                onClick={() => hasSortingOption && column.sortable && sortingHandler(column.title)}
              >
                {column.title}
                {hasSortingOption && column.sortable && getSortingIcon(column)}
                {column?.kind === 'custom' && getCustomTitle()}
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};

export default THead;
