import { ButtonColorTypes, ColumnAlignOptions } from './randomConstants';
import * as Yup from 'yup';
import { PrivilegesGroupFields, Status } from '../interfaces/privilegesShapes';
import { createContext } from 'react';

export interface IStickyContext {
  leftSticky?: string;
  rightSticky?: string;
  rightStickySecond?: string;
  rightStickyThird?: string;
  bottomSticky?: {
    key: string;
    val: string;
  };
}

export const StickyContext = createContext<IStickyContext>({
  leftSticky: '',
  rightSticky: '',
  rightStickySecond: '',
  rightStickyThird: '',
  bottomSticky: { key: '', val: '' }
});

export enum ErrorRowKeys {
  LostPosition = 'isLostPosition'
}

export interface IErrorRowContext {
  errorKey: string;
}

export const ErrorRowContext = createContext<IErrorRowContext>({
  errorKey: ''
});

export enum PrivilegesColIds {
  NAME = 'name',
  SAVE = 'save',
  DELETE = 'delete',
  EXCHANGE_ACCOUNTS = 'exchange_accounts',
  BOT_STATUS = 'bot_status',
  GROUPS = 'groups',
  USERS = 'users',
  LOGS = 'logs',
  MM_PROFILES = 'mm_profiles',
  NOTIFICATIONS = 'notifications',
  ORDERS = 'orders',
  APA = 'position_administration',
  PAIRS = 'pairs',
  PROFILE_SETTINGS = 'profile_settings',
  PROFILES = 'profiles',
  PROFILES_STATS = 'profiles_stats',
  PROFILES_STATS_EXPORT = 'profiles_stats_export',
  SIGNALS = 'signals',
  SIGNALS_EXPORT = 'signals_export',
  TG_BOT = 'tg_bot',
  RISK_CONTROL = 'risk_control',
  ACCOUNT_STATS = 'account_stats',
  ACCOUNT_STATS_EXPORT = 'account_stats_export',
  STATS_BY_MONTHS = 'stat_by_months',
  TICKERS_CONFIGS = 'tickers_configs',
  CHARTS = 'charts'
}

export interface PrivilegesRows {
  id: number;
  [PrivilegesColIds.NAME]: string;
  [PrivilegesColIds.SAVE]: { label: string; color: ButtonColorTypes };
  [PrivilegesColIds.DELETE]: { label: string; color: ButtonColorTypes };
  [PrivilegesColIds.EXCHANGE_ACCOUNTS]: Status;
  [PrivilegesColIds.BOT_STATUS]: Status;
  [PrivilegesColIds.GROUPS]: Status;
  [PrivilegesColIds.USERS]: Status;
  [PrivilegesColIds.LOGS]: Status;
  [PrivilegesColIds.MM_PROFILES]: Status;
  [PrivilegesColIds.NOTIFICATIONS]: Status;
  [PrivilegesColIds.ORDERS]: Status;
  [PrivilegesColIds.APA]: Status;
  [PrivilegesColIds.PAIRS]: Status;
  [PrivilegesColIds.PROFILE_SETTINGS]: Status;
  [PrivilegesColIds.PROFILES]: Status;
  [PrivilegesColIds.PROFILES_STATS]: Status;
  [PrivilegesColIds.PROFILES_STATS_EXPORT]: Status;
  [PrivilegesColIds.SIGNALS]: Status;
  [PrivilegesColIds.SIGNALS_EXPORT]: Status;
  [PrivilegesColIds.TG_BOT]: Status;
  [PrivilegesColIds.ACCOUNT_STATS]: Status;
  [PrivilegesColIds.ACCOUNT_STATS_EXPORT]: Status;
  [PrivilegesColIds.STATS_BY_MONTHS]: Status;
  [PrivilegesColIds.TICKERS_CONFIGS]: Status;
  [PrivilegesColIds.CHARTS]: Status;
}

const PRIVILEGES_TABLE_COL_NAMES = {
  [PrivilegesColIds.NAME]: 'Name',
  [PrivilegesColIds.EXCHANGE_ACCOUNTS]: 'Exchange accounts',
  [PrivilegesColIds.BOT_STATUS]: 'Activation/Deactivation bot',
  [PrivilegesColIds.GROUPS]: 'Privileges',
  [PrivilegesColIds.USERS]: 'Users',
  [PrivilegesColIds.CHARTS]: 'Charts',
  [PrivilegesColIds.LOGS]: 'Logs',
  [PrivilegesColIds.MM_PROFILES]: 'MM Profiles',
  [PrivilegesColIds.NOTIFICATIONS]: 'Notifications',
  [PrivilegesColIds.ORDERS]: 'Orders',
  [PrivilegesColIds.APA]: 'APA',
  [PrivilegesColIds.PAIRS]: 'Pairs',
  [PrivilegesColIds.PROFILE_SETTINGS]: 'S-profiles Settings',
  [PrivilegesColIds.PROFILES]: 'S-profiles',
  [PrivilegesColIds.PROFILES_STATS]: 'S-Profiles stats',
  [PrivilegesColIds.PROFILES_STATS_EXPORT]: 'S-Profiles stats Export',
  [PrivilegesColIds.SIGNALS]: 'Signals',
  [PrivilegesColIds.SIGNALS_EXPORT]: 'Signals Export',
  [PrivilegesColIds.TG_BOT]: 'TG Bot',
  [PrivilegesColIds.RISK_CONTROL]: 'RCS',
  [PrivilegesColIds.ACCOUNT_STATS]: 'Acc stats',
  [PrivilegesColIds.ACCOUNT_STATS_EXPORT]: 'Acc stats Export',
  [PrivilegesColIds.STATS_BY_MONTHS]: 'Stats by months',
  [PrivilegesColIds.TICKERS_CONFIGS]: 'Tickers Configs',
  [PrivilegesColIds.SAVE]: 'Save',
  [PrivilegesColIds.DELETE]: 'Delete'
};

export const filterPerPageValues = {
  FULL: 'Full',
  NP: 'NP',
  LIMITED: 'Limited',
  READ_ONLY: 'ReadOnly'
};

export const filterPerPageLabels = {
  [filterPerPageValues.FULL]: 'Full',
  [filterPerPageValues.NP]: 'NP',
  [filterPerPageValues.LIMITED]: 'Limited',
  [filterPerPageValues.READ_ONLY]: 'ReadOnly'
};

const FULL = { value: filterPerPageValues.FULL, label: filterPerPageLabels[filterPerPageValues.FULL] };
const NP = { value: filterPerPageValues.NP, label: filterPerPageLabels[filterPerPageValues.NP] };
const LIMITED = { value: filterPerPageValues.LIMITED, label: filterPerPageLabels[filterPerPageValues.LIMITED] };
const READ_ONLY = { value: filterPerPageValues.READ_ONLY, label: filterPerPageLabels[filterPerPageValues.READ_ONLY] };

export const PRIVILEGES_OPTIONS = {
  [PrivilegesColIds.EXCHANGE_ACCOUNTS]: [FULL, NP, READ_ONLY],
  [PrivilegesColIds.BOT_STATUS]: [FULL, NP],
  [PrivilegesColIds.GROUPS]: [FULL, NP],
  [PrivilegesColIds.USERS]: [FULL, NP],
  [PrivilegesColIds.CHARTS]: [FULL, NP],
  [PrivilegesColIds.LOGS]: [FULL, NP, LIMITED],
  [PrivilegesColIds.MM_PROFILES]: [FULL, NP, LIMITED, READ_ONLY],
  [PrivilegesColIds.NOTIFICATIONS]: [FULL, NP],
  [PrivilegesColIds.ORDERS]: [FULL, NP, LIMITED],
  [PrivilegesColIds.PAIRS]: [FULL, NP, READ_ONLY],
  [PrivilegesColIds.PROFILE_SETTINGS]: [FULL, NP, READ_ONLY],
  [PrivilegesColIds.PROFILES]: [FULL, NP, LIMITED, READ_ONLY],
  [PrivilegesColIds.PROFILES_STATS]: [FULL, NP, LIMITED],
  [PrivilegesColIds.PROFILES_STATS_EXPORT]: [FULL, NP],
  [PrivilegesColIds.SIGNALS]: [FULL, NP, LIMITED, READ_ONLY],
  [PrivilegesColIds.SIGNALS_EXPORT]: [FULL, NP],
  [PrivilegesColIds.TG_BOT]: [FULL, NP],
  [PrivilegesColIds.APA]: [FULL, LIMITED, NP],
  [PrivilegesColIds.RISK_CONTROL]: [FULL, NP],
  [PrivilegesColIds.ACCOUNT_STATS]: [FULL, NP, READ_ONLY],
  [PrivilegesColIds.ACCOUNT_STATS_EXPORT]: [FULL, NP],
  [PrivilegesColIds.STATS_BY_MONTHS]: [FULL, NP],
  [PrivilegesColIds.TICKERS_CONFIGS]: [FULL, NP, READ_ONLY]
};

export const PRIVILEGES_COLUMNS = [
  {
    id: PrivilegesColIds.NAME,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.NAME],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.EXCHANGE_ACCOUNTS,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.EXCHANGE_ACCOUNTS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.BOT_STATUS,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.BOT_STATUS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.GROUPS,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.GROUPS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.USERS,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.USERS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.CHARTS,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.CHARTS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.TICKERS_CONFIGS,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.TICKERS_CONFIGS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.LOGS,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.LOGS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.MM_PROFILES,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.MM_PROFILES],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.NOTIFICATIONS,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.NOTIFICATIONS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.SIGNALS,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.SIGNALS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.ORDERS,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.ORDERS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.APA,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.APA],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.PAIRS,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.PAIRS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.PROFILE_SETTINGS,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.PROFILE_SETTINGS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.PROFILES,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.PROFILES],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.PROFILES_STATS,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.PROFILES_STATS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.PROFILES_STATS_EXPORT,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.PROFILES_STATS_EXPORT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.ACCOUNT_STATS,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.ACCOUNT_STATS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.ACCOUNT_STATS_EXPORT,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.ACCOUNT_STATS_EXPORT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.STATS_BY_MONTHS,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.STATS_BY_MONTHS],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.SIGNALS_EXPORT,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.SIGNALS_EXPORT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.TG_BOT,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.TG_BOT],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.RISK_CONTROL,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.RISK_CONTROL],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.DELETE,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.DELETE],
    align: ColumnAlignOptions.CENTER
  },
  {
    id: PrivilegesColIds.SAVE,
    title: PRIVILEGES_TABLE_COL_NAMES[PrivilegesColIds.SAVE],
    align: ColumnAlignOptions.CENTER
  }
];

export interface PrivilegesGroupFormFields {
  [PrivilegesGroupFields.NAME]: string;
  [PrivilegesGroupFields.EXCHANGE_ACCOUNT]: Status;
  [PrivilegesGroupFields.PAIRS]: Status;
  [PrivilegesGroupFields.TG_BOT]: Status;
  [PrivilegesGroupFields.LOGS]: Status;
  [PrivilegesGroupFields.NOTIFICATIONS]: Status;
  [PrivilegesGroupFields.SIGNALS]: Status;
  [PrivilegesGroupFields.SIGNALS_EXPORT]: Status;
  [PrivilegesGroupFields.ORDERS]: Status;
  [PrivilegesGroupFields.PROFILES_STATS]: Status;
  [PrivilegesGroupFields.PROFILES_STATS_EXPORT]: Status;
  [PrivilegesGroupFields.MM_PROFILES]: Status;
  [PrivilegesGroupFields.PROFILES]: Status;
  [PrivilegesGroupFields.PROFILE_SETTINGS]: Status;
  [PrivilegesGroupFields.BOT_STATUS]: Status;
  [PrivilegesGroupFields.GROUPS]: Status;
  [PrivilegesGroupFields.USERS]: Status;
  [PrivilegesGroupFields.USERS]: Status;
  [PrivilegesGroupFields.APA]: Status;
  [PrivilegesGroupFields.KEXIT]: Status;
  [PrivilegesGroupFields.RISK_CONTROL]: Status;
  [PrivilegesGroupFields.ACCOUNT_STATS]: Status;
  [PrivilegesGroupFields.ACCOUNT_STATS_EXPORT]: Status;
  [PrivilegesGroupFields.STATS_BY_MONTHS]: Status;
  [PrivilegesGroupFields.TICKERS_CONFIGS]: Status;
  [PrivilegesGroupFields.CHARTS]: Status;
}

export const privilegesGroupInitVales: PrivilegesGroupFormFields = {
  [PrivilegesGroupFields.NAME]: '',
  [PrivilegesGroupFields.KEXIT]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.EXCHANGE_ACCOUNT]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.PAIRS]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.TG_BOT]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.LOGS]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.NOTIFICATIONS]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.SIGNALS]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.SIGNALS_EXPORT]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.ORDERS]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.PROFILES_STATS]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.PROFILES_STATS_EXPORT]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.MM_PROFILES]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.PROFILES]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.PROFILE_SETTINGS]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.BOT_STATUS]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.GROUPS]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.USERS]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.APA]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.RISK_CONTROL]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.ACCOUNT_STATS]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.ACCOUNT_STATS_EXPORT]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.STATS_BY_MONTHS]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.TICKERS_CONFIGS]: filterPerPageValues.FULL as Status,
  [PrivilegesGroupFields.CHARTS]: filterPerPageValues.FULL as Status
};

const OPTIONS = [
  { value: filterPerPageValues.FULL, label: filterPerPageLabels[filterPerPageValues.FULL] },
  { value: filterPerPageValues.LIMITED, label: filterPerPageLabels[filterPerPageValues.LIMITED] },
  { value: filterPerPageValues.NP, label: filterPerPageLabels[filterPerPageValues.NP] }
];

export const TABLE_GROUP_COLS = [
  {
    id: PrivilegesGroupFields.EXCHANGE_ACCOUNT,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.EXCHANGE_ACCOUNT]
  },
  {
    id: PrivilegesGroupFields.PAIRS,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.PAIRS]
  },
  {
    id: PrivilegesGroupFields.TG_BOT,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.TG_BOT]
  },
  {
    id: PrivilegesGroupFields.LOGS,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.LOGS]
  },
  {
    id: PrivilegesGroupFields.NOTIFICATIONS,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.NOTIFICATIONS]
  },
  {
    id: PrivilegesGroupFields.SIGNALS,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.SIGNALS]
  },
  {
    id: PrivilegesGroupFields.SIGNALS_EXPORT,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.SIGNALS_EXPORT]
  },
  {
    id: PrivilegesGroupFields.ORDERS,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.ORDERS]
  },
  {
    id: PrivilegesGroupFields.PROFILES_STATS,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.PROFILES_STATS]
  },
  {
    id: PrivilegesGroupFields.PROFILES_STATS_EXPORT,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.PROFILES_STATS_EXPORT]
  },
  {
    id: PrivilegesGroupFields.MM_PROFILES,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.MM_PROFILES]
  },
  {
    id: PrivilegesGroupFields.PROFILES,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.PROFILES]
  },
  {
    id: PrivilegesGroupFields.PROFILE_SETTINGS,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.PROFILE_SETTINGS]
  },
  {
    id: PrivilegesGroupFields.BOT_STATUS,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.BOT_STATUS]
  },
  {
    id: PrivilegesGroupFields.GROUPS,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.GROUPS]
  },
  {
    id: PrivilegesGroupFields.APA,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.APA]
  },
  {
    id: PrivilegesGroupFields.RISK_CONTROL,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.RISK_CONTROL]
  },
  {
    id: PrivilegesGroupFields.USERS,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.USERS]
  },
  {
    id: PrivilegesGroupFields.CHARTS,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.CHARTS]
  },
  {
    id: PrivilegesGroupFields.ACCOUNT_STATS,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.ACCOUNT_STATS]
  },
  {
    id: PrivilegesGroupFields.ACCOUNT_STATS_EXPORT,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.ACCOUNT_STATS_EXPORT]
  },
  {
    id: PrivilegesGroupFields.STATS_BY_MONTHS,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.STATS_BY_MONTHS]
  },
  {
    id: PrivilegesGroupFields.TICKERS_CONFIGS,
    OPTIONS,
    label: PRIVILEGES_TABLE_COL_NAMES[PrivilegesGroupFields.TICKERS_CONFIGS]
  }
];

const VALIDATION_OPTIONS = [
  filterPerPageValues.FULL,
  filterPerPageValues.LIMITED,
  filterPerPageValues.READ_ONLY,
  filterPerPageValues.NP
];

export const groupPrivilegesValidationSchema = {
  [PrivilegesColIds.NAME]: Yup.string().required('Group name is required'),
  [PrivilegesColIds.EXCHANGE_ACCOUNTS]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.BOT_STATUS]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.GROUPS]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.LOGS]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.MM_PROFILES]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.NOTIFICATIONS]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.ORDERS]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.PAIRS]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.PROFILE_SETTINGS]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.PROFILES]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.PROFILES_STATS]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.PROFILES_STATS_EXPORT]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.SIGNALS]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.SIGNALS_EXPORT]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.TG_BOT]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.APA]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.RISK_CONTROL]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.USERS]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.ACCOUNT_STATS]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.ACCOUNT_STATS_EXPORT]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.STATS_BY_MONTHS]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.TICKERS_CONFIGS]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required'),
  [PrivilegesColIds.CHARTS]: Yup.mixed().oneOf(VALIDATION_OPTIONS).required('This field is required')
};

export enum SIGNALS_COLS {
  ACTION_STATE = 'action_state',
  ATR_MINUS_1 = 'atr_minus_1',
  ATR_T_PERCENT = 'atr_t_percent',
  AVG_E_PRICE = 'avg_e_price',
  CREATED_AT = 'created_at',
  CURRENT_PRICE = 'current_price',
  DIRECTION = 'direction',
  EXECUTION = 'execution',
  EXIT_PLACE = 'exit_place',
  ID = 'id',
  INC_VOLA = 'inc_vola',
  MINUTE_ACTIVATED_AT = 'minute_activated_at',
  PAIR = 'pair',
  PNL_IN_PERCENT = 'pnl_in_percent',
  PNL_IN_USD = 'pnl_in_usd',
  PROFILE = 'profile',
  SIZE_COINS = 'size_coins',
  SIZE_CURRENCY = 'size_currency',
  START_POINT = 'start_point',
  STATE = 'state',
  STATUS = 'status',
  TIME_PER_SIGNAL = 'time_per_signal',
  TIMEFRAME = 'timeframe',
  TR_IN_ATR = 'tr_in_atr',
  CANCEL = 'cancel',
  CLOSE_MARKET = 'close_market',
  CLOSE_LIMIT = 'close_limit',
  PROFILE_ID = 'profile_id'
}

export const SIGNALS_FIELDS = [
  SIGNALS_COLS.ACTION_STATE,
  SIGNALS_COLS.ATR_MINUS_1,
  SIGNALS_COLS.ATR_T_PERCENT,
  SIGNALS_COLS.AVG_E_PRICE,
  SIGNALS_COLS.CREATED_AT,
  SIGNALS_COLS.CURRENT_PRICE,
  SIGNALS_COLS.DIRECTION,
  SIGNALS_COLS.EXECUTION,
  SIGNALS_COLS.EXIT_PLACE,
  SIGNALS_COLS.ID,
  SIGNALS_COLS.INC_VOLA,
  SIGNALS_COLS.MINUTE_ACTIVATED_AT,
  SIGNALS_COLS.PAIR,
  SIGNALS_COLS.PNL_IN_PERCENT,
  SIGNALS_COLS.PNL_IN_USD,
  SIGNALS_COLS.PROFILE,
  SIGNALS_COLS.SIZE_COINS,
  SIGNALS_COLS.SIZE_CURRENCY,
  SIGNALS_COLS.START_POINT,
  SIGNALS_COLS.STATE,
  SIGNALS_COLS.STATUS,
  SIGNALS_COLS.TIME_PER_SIGNAL,
  SIGNALS_COLS.TIMEFRAME,
  SIGNALS_COLS.TR_IN_ATR,
  SIGNALS_COLS.CANCEL,
  SIGNALS_COLS.CLOSE_MARKET,
  SIGNALS_COLS.CLOSE_LIMIT
];

export const SIGNALS_TABLE_COL_LABELS = {
  [SIGNALS_COLS.CREATED_AT]: 'Date',
  [SIGNALS_COLS.PROFILE]: 'Strategy profile',
  [SIGNALS_COLS.TIMEFRAME]: 'TF',
  [SIGNALS_COLS.ID]: 'Signal ID',
  [SIGNALS_COLS.PAIR]: 'Ticker',
  [SIGNALS_COLS.DIRECTION]: 'S-Direction',
  [SIGNALS_COLS.EXECUTION]: 'Execution',
  [SIGNALS_COLS.SIZE_CURRENCY]: 'Position size $',
  [SIGNALS_COLS.SIZE_COINS]: 'Position size coins',
  [SIGNALS_COLS.AVG_E_PRICE]: 'Avg. e-price',
  [SIGNALS_COLS.EXIT_PLACE]: 'Exit price',
  [SIGNALS_COLS.CURRENT_PRICE]: 'Current price',
  [SIGNALS_COLS.PNL_IN_USD]: 'PNL ($)',
  [SIGNALS_COLS.PNL_IN_PERCENT]: 'PNL (%)',
  [SIGNALS_COLS.TR_IN_ATR]: 'TRinATR',
  [SIGNALS_COLS.INC_VOLA]: 'Inc.Vola',
  [SIGNALS_COLS.ATR_MINUS_1]: 'ATR(i-1)%',
  [SIGNALS_COLS.MINUTE_ACTIVATED_AT]: 'A-Period',
  [SIGNALS_COLS.START_POINT]: 'Start-Point',
  [SIGNALS_COLS.STATUS]: 'Status',
  [SIGNALS_COLS.TIME_PER_SIGNAL]: 'Time per signal',
  [SIGNALS_COLS.STATE]: 'State',
  [SIGNALS_COLS.ACTION_STATE]: 'Action State',
  [SIGNALS_COLS.ATR_T_PERCENT]: 'ATR-T(%)',
  [SIGNALS_COLS.CANCEL]: 'Cancel',
  [SIGNALS_COLS.CLOSE_MARKET]: 'Close Market',
  [SIGNALS_COLS.CLOSE_LIMIT]: 'Close Limit',
  [SIGNALS_COLS.PROFILE_ID]: 'Alternative Title'
};

export enum OrdersColIds {
  DATE = 'created_at',
  SIGNAL_ID = 'signal',
  STRATEGY_PROFILE = 'profile',
  TICKER_PAIR = 'pair',
  ORDER_DIRECTION = 'direction',
  E_PRICE = 'price',
  ORDER_SIZE_COINS = 'quantity',
  ID = 'id',
  POSITION_DIRECTION = 'position_direction',
  STATE = 'state',
  ORDER_ID = 'exchange_order_id',
  ORDER_TYPE = 'order_type',
  ORDER_SIZE = 'o_size',
  ORDER_STATE = 'status',
  ACTION = 'action'
}

export const ORDERS_FIELDS = [
  OrdersColIds.DATE,
  OrdersColIds.SIGNAL_ID,
  OrdersColIds.STRATEGY_PROFILE,
  OrdersColIds.TICKER_PAIR,
  OrdersColIds.ORDER_DIRECTION,
  OrdersColIds.E_PRICE,
  OrdersColIds.ORDER_SIZE_COINS,
  OrdersColIds.ID,
  OrdersColIds.POSITION_DIRECTION,
  OrdersColIds.STATE,
  OrdersColIds.ORDER_ID,
  OrdersColIds.ORDER_TYPE,
  OrdersColIds.ORDER_SIZE,
  OrdersColIds.ORDER_STATE,
  OrdersColIds.ACTION
];

export const ORDERS_TABLE_COL_NAMES = {
  [OrdersColIds.DATE]: 'Date',
  [OrdersColIds.SIGNAL_ID]: 'Signal ID',
  [OrdersColIds.STRATEGY_PROFILE]: 'Strategy profile',
  [OrdersColIds.TICKER_PAIR]: 'Ticker/pair',
  [OrdersColIds.ORDER_DIRECTION]: 'O-Direction',
  [OrdersColIds.ID]: 'Order ID',
  [OrdersColIds.POSITION_DIRECTION]: 'Order position direction',
  [OrdersColIds.E_PRICE]: 'E-price',
  [OrdersColIds.ORDER_SIZE_COINS]: 'O-size coins',
  [OrdersColIds.STATE]: 'Order state',
  [OrdersColIds.ORDER_ID]: 'Exchange Order ID',
  [OrdersColIds.ORDER_TYPE]: 'Order Type',
  [OrdersColIds.ORDER_SIZE]: 'Order Size',
  [OrdersColIds.ORDER_STATE]: 'Order Status',
  [OrdersColIds.ACTION]: 'Action'
};

export enum MMTableColIds {
  IS_SHOW = 'is_show',
  DATE = 'created_at',
  ALL_SIGNAL_QUOTA = 'all_signals_quota',
  EXCHANGE_ACC_NAME = 'exchange_account',
  MAX_SIGNALS = 'max_signals_amount',
  MAX_FACTOR = 'max_factor_amount',
  PROFILES = 'profiles',
  STATUS = 'status',
  TITLE = 'title',
  ONE_SINGLE_QUOTA = 'one_signal_quota',
  ACTION = 'action',
  CHANGE_DEPOSIT = 'change_deposit'
}

export const MM_PROFILES_FIELDS = [
  MMTableColIds.IS_SHOW,
  MMTableColIds.DATE,
  MMTableColIds.ALL_SIGNAL_QUOTA,
  MMTableColIds.EXCHANGE_ACC_NAME,
  MMTableColIds.MAX_SIGNALS,
  MMTableColIds.MAX_FACTOR,
  MMTableColIds.PROFILES,
  MMTableColIds.STATUS,
  MMTableColIds.TITLE,
  MMTableColIds.ONE_SINGLE_QUOTA,
  MMTableColIds.ACTION,
  MMTableColIds.CHANGE_DEPOSIT
];

const MM_TABLE_COL_NAMES = {
  [MMTableColIds.TITLE]: 'MM profile name',
  [MMTableColIds.PROFILES]: 'Strategy profiles',
  [MMTableColIds.MAX_FACTOR]: 'Max factor',
  [MMTableColIds.MAX_SIGNALS]: 'Max signals',
  [MMTableColIds.DATE]: 'Date',
  [MMTableColIds.ALL_SIGNAL_QUOTA]: 'All signals quota($)',
  [MMTableColIds.EXCHANGE_ACC_NAME]: 'Exchange acc name',
  [MMTableColIds.STATUS]: 'Status',
  [MMTableColIds.IS_SHOW]: 'Visibility',
  [MMTableColIds.ONE_SINGLE_QUOTA]: 'One signal quota($)',
  [MMTableColIds.ACTION]: 'Actions',
  [MMTableColIds.CHANGE_DEPOSIT]: 'Change Deposit'
};

export enum StrategyColIds {
  EXCHANGE_TITLE = 'exchange_account',
  STRATEGY_TITLE = 'title',
  PROFILE_DIRECTION = 'profile_direction',
  TIME_FRAME = 'timeframe',
  TRADING_PAIRS = 'pairs',
  MM_PROFILE_TITLE = 'mm_profile',
  STATUS = 'status',
  VISIBILITY = 'is_show',
  ACTION = 'action',
  ADD = 'add'
}

export const PROFILES_FIELDS = [
  StrategyColIds.EXCHANGE_TITLE,
  StrategyColIds.STRATEGY_TITLE,
  StrategyColIds.PROFILE_DIRECTION,
  StrategyColIds.TIME_FRAME,
  StrategyColIds.TRADING_PAIRS,
  StrategyColIds.MM_PROFILE_TITLE,
  StrategyColIds.STATUS,
  StrategyColIds.VISIBILITY,
  StrategyColIds.ACTION,
  StrategyColIds.ADD
];

export const STRATEGY_TABLE_COL_NAMES = {
  [StrategyColIds.EXCHANGE_TITLE]: 'Exchange account name',
  [StrategyColIds.STRATEGY_TITLE]: 'Strategy profile name',
  [StrategyColIds.PROFILE_DIRECTION]: 'Price direction',
  [StrategyColIds.TIME_FRAME]: 'Time frame',
  [StrategyColIds.TRADING_PAIRS]: 'Ticker list name',
  [StrategyColIds.MM_PROFILE_TITLE]: 'MM profile name',
  [StrategyColIds.STATUS]: 'Status',
  [StrategyColIds.VISIBILITY]: 'Visibility',
  [StrategyColIds.ACTION]: 'Action',
  [StrategyColIds.ADD]: 'Add btn'
};

export enum LogsColIds {
  ID = 'id',
  DATE = 'created_at',
  TYPE = 'type',
  SIGNAL_ID = 'signal_id',
  PAIR_ID = 'pair_id',
  PROFILE_TITLE = 'profile_title',
  LOG_DATA = 'log_data',
  ORDER_ID = 'order_id',
  EVENT_TYPE = 'event_type',
  PAIR_NAME = 'pair_name',
  PROFILE_ID = 'profile_id'
}

export const LOGS_FIELDS = [
  LogsColIds.ID,
  LogsColIds.DATE,
  LogsColIds.TYPE,
  LogsColIds.SIGNAL_ID,
  LogsColIds.PAIR_ID,
  LogsColIds.PROFILE_TITLE,
  LogsColIds.LOG_DATA,
  LogsColIds.ORDER_ID,
  LogsColIds.EVENT_TYPE,
  LogsColIds.PAIR_NAME,
  LogsColIds.PROFILE_ID
];

export const LOGS_TABLE_COL_NAMES = {
  [LogsColIds.ID]: 'ID',
  [LogsColIds.DATE]: 'Date & time',
  [LogsColIds.TYPE]: 'Event',
  [LogsColIds.SIGNAL_ID]: 'Signal',
  [LogsColIds.PAIR_ID]: 'Pair id',
  [LogsColIds.PROFILE_TITLE]: 'Profile',
  [LogsColIds.LOG_DATA]: 'Message',
  [LogsColIds.ORDER_ID]: 'Order',
  [LogsColIds.EVENT_TYPE]: 'Event type',
  [LogsColIds.PAIR_NAME]: 'Pair name',
  [LogsColIds.PROFILE_ID]: 'Profile id'
};

export enum ProfilesStatsColIds {
  PROFILE_TITLE = 'profile_title',
  PROFILE_ID = 'profile_id',
  DIRECTION = 'direction',
  TOTAL_PROFIT = 'total_profit',
  TOTAL_LOSS = 'total_loss',
  TOTAL = 'total',
  TOTAL_PERCENT = 'total_percent',
  TOTAL_I = 'total_i',
  AVG_PLN_PERCENT = 'avg_pnl_percent',
  AVG_LOSS_PERCENT = 'avg_loss_percent',
  MOST_LOSS_PERCENT = 'most_loss_percent',
  AVG_PROFIT_PERCENT = 'avg_profit_percent',
  AVG_I = 'avg_i',
  QTY_LOSS = 'qty_loss',
  QTY_PROFIT = 'qty_profit',
  QTY_TOTAL = 'qty_total',
  W_PERCENT = 'w_percent',
  AVG_TIME_PER_SIGNAL = 'avg_time_per_signal',
  MOST_LONGEST_DURATION = 'most_longest_duration',
  MOST_IMP_LOSS = 'most_imp_loss',
  MOST_MM_IMP_LOSS = 'most_mm_imp_loss',
  QTY_MISSED = 'qty_missed',
  STATUS = 'status'
}
export const PROFILES_STATS_FIELDS = [
  ProfilesStatsColIds.PROFILE_TITLE,
  ProfilesStatsColIds.DIRECTION,
  ProfilesStatsColIds.TOTAL_PROFIT,
  ProfilesStatsColIds.TOTAL_LOSS,
  ProfilesStatsColIds.TOTAL,
  ProfilesStatsColIds.TOTAL_PERCENT,
  ProfilesStatsColIds.TOTAL_I,
  ProfilesStatsColIds.AVG_PLN_PERCENT,
  ProfilesStatsColIds.AVG_LOSS_PERCENT,
  ProfilesStatsColIds.MOST_LOSS_PERCENT,
  ProfilesStatsColIds.AVG_PROFIT_PERCENT,
  ProfilesStatsColIds.AVG_I,
  ProfilesStatsColIds.QTY_LOSS,
  ProfilesStatsColIds.QTY_PROFIT,
  ProfilesStatsColIds.QTY_TOTAL,
  ProfilesStatsColIds.W_PERCENT,
  ProfilesStatsColIds.AVG_TIME_PER_SIGNAL,
  ProfilesStatsColIds.MOST_LONGEST_DURATION,
  ProfilesStatsColIds.MOST_IMP_LOSS,
  ProfilesStatsColIds.MOST_MM_IMP_LOSS,
  ProfilesStatsColIds.QTY_MISSED,
  ProfilesStatsColIds.STATUS
];

export const PROFILE_STATS_TABLE_COL_NAMES = {
  [ProfilesStatsColIds.PROFILE_TITLE]: 'Title',
  [ProfilesStatsColIds.PROFILE_ID]: 'Alternative Title',
  [ProfilesStatsColIds.DIRECTION]: 'Direction',
  [ProfilesStatsColIds.TOTAL_PROFIT]: 'Total Profit',
  [ProfilesStatsColIds.TOTAL_LOSS]: 'Total loss',
  [ProfilesStatsColIds.TOTAL]: 'Total',
  [ProfilesStatsColIds.TOTAL_PERCENT]: 'Total percent',
  [ProfilesStatsColIds.TOTAL_I]: 'PF',
  [ProfilesStatsColIds.AVG_PLN_PERCENT]: 'Avg Pln %',
  [ProfilesStatsColIds.AVG_LOSS_PERCENT]: 'Avg loss %',
  [ProfilesStatsColIds.MOST_LOSS_PERCENT]: 'Most loss %',
  [ProfilesStatsColIds.AVG_PROFIT_PERCENT]: 'Avg profit %',
  [ProfilesStatsColIds.AVG_I]: 'Avg I',
  [ProfilesStatsColIds.QTY_LOSS]: 'QTY loss',
  [ProfilesStatsColIds.QTY_PROFIT]: 'QTY profit',
  [ProfilesStatsColIds.QTY_TOTAL]: 'QTY total',
  [ProfilesStatsColIds.W_PERCENT]: 'W percent',
  [ProfilesStatsColIds.AVG_TIME_PER_SIGNAL]: 'Avg time/signal',
  [ProfilesStatsColIds.MOST_LONGEST_DURATION]: 'Most longest duration',
  [ProfilesStatsColIds.MOST_IMP_LOSS]: 'Most IMP loss',
  [ProfilesStatsColIds.MOST_MM_IMP_LOSS]: 'Most mm IMP loss',
  [ProfilesStatsColIds.QTY_MISSED]: 'QTY missed',
  [ProfilesStatsColIds.STATUS]: 'Status'
};

export enum APAColIds {
  CREATED_AT = 'created_at',
  PROFILE = 'profile',
  ID = 'id',
  TICKER = 'ticker',
  ACTIVE_P_SIZE_USD = 'active_p_size_usd',
  ACTIVE_P_COINS = 'active_p_coins',
  R_PLN = 'r_pnl',
  U_PLN = 'u_pnl',
  AVG_E_PRICE = 'avg_e_price',
  K_EXIT = 'k_exit',
  CURRENT_PRICE = 'current_price',
  MARK_PRICE = 'mark_price',
  K_EXIT_COEFF = 'k_exit_coeff',
  CLOSE_MARKET = 'close_market',
  CLOSE_LIMIT = 'close_limit',
  STATE = 'state',
  REDUCE_P = 'reduce_p',
  KE_RECOVERY = 'k_e_recovery',
  PROFILE_LIST = 'profile_list'
}
export const APA_FIELDS = [
  APAColIds.CREATED_AT,
  APAColIds.PROFILE,
  APAColIds.ID,
  APAColIds.TICKER,
  APAColIds.ACTIVE_P_SIZE_USD,
  APAColIds.ACTIVE_P_COINS,
  APAColIds.R_PLN,
  APAColIds.U_PLN,
  APAColIds.AVG_E_PRICE,
  APAColIds.K_EXIT,
  APAColIds.CURRENT_PRICE,
  APAColIds.MARK_PRICE,
  APAColIds.K_EXIT_COEFF,
  APAColIds.CLOSE_MARKET,
  APAColIds.CLOSE_LIMIT,
  APAColIds.REDUCE_P,
  APAColIds.KE_RECOVERY,
  APAColIds.STATE,
  APAColIds.PROFILE_LIST
];

export const APA_TABLE_COL_NAMES = {
  [APAColIds.CREATED_AT]: 'Date',
  [APAColIds.PROFILE]: 'Strategy name',
  [APAColIds.ID]: 'Signal ID',
  [APAColIds.TICKER]: 'Ticker',
  [APAColIds.ACTIVE_P_SIZE_USD]: 'Active p-size ($)',
  [APAColIds.ACTIVE_P_COINS]: 'Active p-coins',
  [APAColIds.R_PLN]: 'R-Pnl ($)',
  [APAColIds.U_PLN]: 'U-Pnl ($)',
  [APAColIds.AVG_E_PRICE]: 'Avg.E.Price',
  [APAColIds.K_EXIT]: 'kExit',
  [APAColIds.CURRENT_PRICE]: 'Curr. price',
  [APAColIds.MARK_PRICE]: 'Mark price',
  [APAColIds.K_EXIT_COEFF]: 'kExit coeff',
  [APAColIds.CLOSE_MARKET]: 'Close by market',
  [APAColIds.CLOSE_LIMIT]: 'Close by limit',
  [APAColIds.REDUCE_P]: 'Reduce P',
  [APAColIds.KE_RECOVERY]: 'Ke Recovery',
  [APAColIds.PROFILE_LIST]: 'Profile details',
  [APAColIds.STATE]: 'State'
};

export const LIMITED_FIELDS_MAP = {
  [PrivilegesColIds.SIGNALS]: SIGNALS_FIELDS,
  [PrivilegesColIds.ORDERS]: ORDERS_FIELDS,
  [PrivilegesColIds.MM_PROFILES]: MM_PROFILES_FIELDS,
  [PrivilegesColIds.PROFILES]: PROFILES_FIELDS,
  [PrivilegesColIds.PROFILES_STATS]: PROFILES_STATS_FIELDS,
  [PrivilegesColIds.LOGS]: LOGS_FIELDS,
  [PrivilegesColIds.APA]: APA_FIELDS
};

// better name for it
export const FIELDS_MAP = {
  [PrivilegesColIds.SIGNALS]: SIGNALS_FIELDS,
  [PrivilegesColIds.ORDERS]: ORDERS_FIELDS,
  [PrivilegesColIds.MM_PROFILES]: MM_PROFILES_FIELDS,
  [PrivilegesColIds.PROFILES]: PROFILES_FIELDS,
  [PrivilegesColIds.PROFILES_STATS]: PROFILES_STATS_FIELDS,
  [PrivilegesColIds.LOGS]: LOGS_FIELDS,
  [PrivilegesColIds.APA]: APA_FIELDS
};

export const FIELD_DEPENDENCIES_MAP = {
  [PrivilegesColIds.SIGNALS]: [SIGNALS_COLS.PROFILE, SIGNALS_COLS.PROFILE_ID] as string[],
  [PrivilegesColIds.PROFILES_STATS]: [ProfilesStatsColIds.PROFILE_TITLE, ProfilesStatsColIds.PROFILE_ID] as string[]
};

export const FIELDS_HAS_COLS = [
  PrivilegesColIds.SIGNALS,
  PrivilegesColIds.ORDERS,
  PrivilegesColIds.MM_PROFILES,
  PrivilegesColIds.PROFILES,
  PrivilegesColIds.PROFILES_STATS,
  PrivilegesColIds.APA,
  PrivilegesColIds.LOGS as string
];

export const FILTERING_FIELDS = [PrivilegesColIds.LOGS, PrivilegesColIds.SIGNALS, PrivilegesColIds.PROFILES_STATS];
export const SORTING_FIELDS = [PrivilegesColIds.SIGNALS, PrivilegesColIds.PROFILES_STATS];
export const ONLY_FINISHED_FILED = [PrivilegesColIds.SIGNALS];
export const COUNT_FIELDS = [PrivilegesColIds.SIGNALS];

export const LOGS_FIELDS_PICK = [
  {
    id: LogsColIds.ID,
    label: LOGS_TABLE_COL_NAMES[LogsColIds.ID]
  },
  {
    id: LogsColIds.DATE,
    label: LOGS_TABLE_COL_NAMES[LogsColIds.DATE]
  },
  {
    id: LogsColIds.TYPE,
    label: LOGS_TABLE_COL_NAMES[LogsColIds.TYPE]
  },
  {
    id: LogsColIds.SIGNAL_ID,
    label: LOGS_TABLE_COL_NAMES[LogsColIds.SIGNAL_ID]
  },
  {
    id: LogsColIds.PAIR_ID,
    label: LOGS_TABLE_COL_NAMES[LogsColIds.PAIR_ID]
  },
  {
    id: LogsColIds.PROFILE_TITLE,
    label: LOGS_TABLE_COL_NAMES[LogsColIds.PROFILE_TITLE]
  },
  {
    id: LogsColIds.LOG_DATA,
    label: LOGS_TABLE_COL_NAMES[LogsColIds.LOG_DATA]
  },
  {
    id: LogsColIds.ORDER_ID,
    label: LOGS_TABLE_COL_NAMES[LogsColIds.ORDER_ID]
  },
  {
    id: LogsColIds.EVENT_TYPE,
    label: LOGS_TABLE_COL_NAMES[LogsColIds.EVENT_TYPE]
  },
  {
    id: LogsColIds.PAIR_NAME,
    label: LOGS_TABLE_COL_NAMES[LogsColIds.PAIR_NAME]
  },
  {
    id: LogsColIds.PROFILE_ID,
    label: LOGS_TABLE_COL_NAMES[LogsColIds.PROFILE_ID]
  }
];

export const PROFILES_FIELDS_PICK = [
  {
    id: StrategyColIds.EXCHANGE_TITLE,
    label: STRATEGY_TABLE_COL_NAMES[StrategyColIds.EXCHANGE_TITLE]
  },
  {
    id: StrategyColIds.STRATEGY_TITLE,
    label: STRATEGY_TABLE_COL_NAMES[StrategyColIds.STRATEGY_TITLE]
  },
  {
    id: StrategyColIds.PROFILE_DIRECTION,
    label: STRATEGY_TABLE_COL_NAMES[StrategyColIds.PROFILE_DIRECTION]
  },
  {
    id: StrategyColIds.TIME_FRAME,
    label: STRATEGY_TABLE_COL_NAMES[StrategyColIds.TIME_FRAME]
  },
  {
    id: StrategyColIds.TRADING_PAIRS,
    label: STRATEGY_TABLE_COL_NAMES[StrategyColIds.TRADING_PAIRS]
  },
  {
    id: StrategyColIds.MM_PROFILE_TITLE,
    label: STRATEGY_TABLE_COL_NAMES[StrategyColIds.MM_PROFILE_TITLE]
  },
  {
    id: StrategyColIds.STATUS,
    label: STRATEGY_TABLE_COL_NAMES[StrategyColIds.STATUS]
  },
  {
    id: StrategyColIds.VISIBILITY,
    label: STRATEGY_TABLE_COL_NAMES[StrategyColIds.VISIBILITY]
  },
  {
    id: StrategyColIds.ACTION,
    label: STRATEGY_TABLE_COL_NAMES[StrategyColIds.ACTION]
  },
  {
    id: StrategyColIds.ADD,
    label: STRATEGY_TABLE_COL_NAMES[StrategyColIds.ADD]
  }
];

export const PROFILES_STATS_FIELDS_PICK = [
  {
    id: ProfilesStatsColIds.PROFILE_TITLE,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.PROFILE_TITLE]
  },
  {
    id: ProfilesStatsColIds.DIRECTION,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.DIRECTION]
  },
  {
    id: ProfilesStatsColIds.TOTAL_PROFIT,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.TOTAL_PROFIT]
  },
  {
    id: ProfilesStatsColIds.TOTAL_LOSS,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.TOTAL_LOSS]
  },
  {
    id: ProfilesStatsColIds.TOTAL,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.TOTAL]
  },
  {
    id: ProfilesStatsColIds.TOTAL_PERCENT,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.TOTAL_PERCENT]
  },
  {
    id: ProfilesStatsColIds.TOTAL_I,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.TOTAL_I]
  },
  {
    id: ProfilesStatsColIds.AVG_PLN_PERCENT,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.AVG_PLN_PERCENT]
  },
  {
    id: ProfilesStatsColIds.AVG_LOSS_PERCENT,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.AVG_LOSS_PERCENT]
  },
  {
    id: ProfilesStatsColIds.MOST_LOSS_PERCENT,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.MOST_LOSS_PERCENT]
  },
  {
    id: ProfilesStatsColIds.AVG_PROFIT_PERCENT,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.AVG_PROFIT_PERCENT]
  },
  {
    id: ProfilesStatsColIds.AVG_I,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.AVG_I]
  },
  {
    id: ProfilesStatsColIds.QTY_LOSS,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.QTY_LOSS]
  },
  {
    id: ProfilesStatsColIds.QTY_PROFIT,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.QTY_PROFIT]
  },
  {
    id: ProfilesStatsColIds.QTY_TOTAL,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.QTY_TOTAL]
  },
  {
    id: ProfilesStatsColIds.W_PERCENT,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.W_PERCENT]
  },
  {
    id: ProfilesStatsColIds.AVG_TIME_PER_SIGNAL,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.AVG_TIME_PER_SIGNAL]
  },
  {
    id: ProfilesStatsColIds.MOST_LONGEST_DURATION,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.MOST_LONGEST_DURATION]
  },
  {
    id: ProfilesStatsColIds.MOST_IMP_LOSS,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.MOST_IMP_LOSS]
  },
  {
    id: ProfilesStatsColIds.MOST_MM_IMP_LOSS,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.MOST_MM_IMP_LOSS]
  },
  {
    id: ProfilesStatsColIds.QTY_MISSED,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.QTY_MISSED]
  },
  {
    id: ProfilesStatsColIds.STATUS,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.STATUS]
  },
  {
    id: ProfilesStatsColIds.PROFILE_ID,
    label: PROFILE_STATS_TABLE_COL_NAMES[ProfilesStatsColIds.PROFILE_ID]
  }
];

export const MM_PROFILES_FIELDS_PICK = [
  {
    id: MMTableColIds.TITLE,
    label: MM_TABLE_COL_NAMES[MMTableColIds.TITLE]
  },
  {
    id: MMTableColIds.PROFILES,
    label: MM_TABLE_COL_NAMES[MMTableColIds.PROFILES]
  },
  {
    id: MMTableColIds.MAX_FACTOR,
    label: MM_TABLE_COL_NAMES[MMTableColIds.MAX_FACTOR]
  },
  {
    id: MMTableColIds.MAX_SIGNALS,
    label: MM_TABLE_COL_NAMES[MMTableColIds.MAX_SIGNALS]
  },
  {
    id: MMTableColIds.DATE,
    label: MM_TABLE_COL_NAMES[MMTableColIds.DATE]
  },
  {
    id: MMTableColIds.ALL_SIGNAL_QUOTA,
    label: MM_TABLE_COL_NAMES[MMTableColIds.ALL_SIGNAL_QUOTA]
  },
  {
    id: MMTableColIds.EXCHANGE_ACC_NAME,
    label: MM_TABLE_COL_NAMES[MMTableColIds.EXCHANGE_ACC_NAME]
  },
  {
    id: MMTableColIds.STATUS,
    label: MM_TABLE_COL_NAMES[MMTableColIds.STATUS]
  },
  {
    id: MMTableColIds.IS_SHOW,
    label: MM_TABLE_COL_NAMES[MMTableColIds.IS_SHOW]
  },
  {
    id: MMTableColIds.ONE_SINGLE_QUOTA,
    label: MM_TABLE_COL_NAMES[MMTableColIds.ONE_SINGLE_QUOTA]
  },
  {
    id: MMTableColIds.ACTION,
    label: MM_TABLE_COL_NAMES[MMTableColIds.ACTION]
  },
  {
    id: MMTableColIds.CHANGE_DEPOSIT,
    label: MM_TABLE_COL_NAMES[MMTableColIds.CHANGE_DEPOSIT]
  }
];

export const ORDERS_FIELDS_PICK = [
  {
    id: OrdersColIds.DATE,
    label: ORDERS_TABLE_COL_NAMES[OrdersColIds.DATE]
  },
  {
    id: OrdersColIds.SIGNAL_ID,
    label: ORDERS_TABLE_COL_NAMES[OrdersColIds.SIGNAL_ID]
  },
  {
    id: OrdersColIds.STRATEGY_PROFILE,
    label: ORDERS_TABLE_COL_NAMES[OrdersColIds.STRATEGY_PROFILE]
  },
  {
    id: OrdersColIds.TICKER_PAIR,
    label: ORDERS_TABLE_COL_NAMES[OrdersColIds.TICKER_PAIR]
  },
  {
    id: OrdersColIds.ORDER_DIRECTION,
    label: ORDERS_TABLE_COL_NAMES[OrdersColIds.ORDER_DIRECTION]
  },
  {
    id: OrdersColIds.ID,
    label: ORDERS_TABLE_COL_NAMES[OrdersColIds.ID]
  },
  {
    id: OrdersColIds.POSITION_DIRECTION,
    label: ORDERS_TABLE_COL_NAMES[OrdersColIds.POSITION_DIRECTION]
  },
  {
    id: OrdersColIds.E_PRICE,
    label: ORDERS_TABLE_COL_NAMES[OrdersColIds.E_PRICE]
  },
  {
    id: OrdersColIds.ORDER_SIZE_COINS,
    label: ORDERS_TABLE_COL_NAMES[OrdersColIds.ORDER_SIZE_COINS]
  },
  {
    id: OrdersColIds.STATE,
    label: ORDERS_TABLE_COL_NAMES[OrdersColIds.STATE]
  },
  {
    id: OrdersColIds.ORDER_ID,
    label: ORDERS_TABLE_COL_NAMES[OrdersColIds.ORDER_ID]
  },
  {
    id: OrdersColIds.ORDER_TYPE,
    label: ORDERS_TABLE_COL_NAMES[OrdersColIds.ORDER_TYPE]
  },
  {
    id: OrdersColIds.ORDER_SIZE,
    label: ORDERS_TABLE_COL_NAMES[OrdersColIds.ORDER_SIZE]
  },
  {
    id: OrdersColIds.ORDER_STATE,
    label: ORDERS_TABLE_COL_NAMES[OrdersColIds.ORDER_STATE]
  },
  {
    id: OrdersColIds.ACTION,
    label: ORDERS_TABLE_COL_NAMES[OrdersColIds.ACTION]
  }
];

export const SIGNALS_FIELDS_PICK = [
  {
    id: SIGNALS_COLS.ACTION_STATE,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.ACTION_STATE]
  },
  {
    id: SIGNALS_COLS.ATR_MINUS_1,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.ATR_MINUS_1]
  },
  {
    id: SIGNALS_COLS.ATR_T_PERCENT,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.ATR_T_PERCENT]
  },
  {
    id: SIGNALS_COLS.AVG_E_PRICE,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.AVG_E_PRICE]
  },
  {
    id: SIGNALS_COLS.CREATED_AT,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.CREATED_AT]
  },
  {
    id: SIGNALS_COLS.CURRENT_PRICE,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.CURRENT_PRICE]
  },
  {
    id: SIGNALS_COLS.DIRECTION,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.DIRECTION]
  },
  {
    id: SIGNALS_COLS.EXECUTION,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.EXECUTION]
  },
  {
    id: SIGNALS_COLS.EXIT_PLACE,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.EXIT_PLACE]
  },
  {
    id: SIGNALS_COLS.ID,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.ID]
  },
  {
    id: SIGNALS_COLS.INC_VOLA,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.INC_VOLA]
  },
  {
    id: SIGNALS_COLS.MINUTE_ACTIVATED_AT,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.MINUTE_ACTIVATED_AT]
  },
  {
    id: SIGNALS_COLS.PAIR,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.PAIR]
  },
  {
    id: SIGNALS_COLS.PNL_IN_PERCENT,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.PNL_IN_PERCENT]
  },
  {
    id: SIGNALS_COLS.PNL_IN_USD,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.PNL_IN_USD]
  },
  {
    id: SIGNALS_COLS.PROFILE,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.PROFILE]
  },
  {
    id: SIGNALS_COLS.SIZE_COINS,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.SIZE_COINS]
  },
  {
    id: SIGNALS_COLS.SIZE_CURRENCY,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.SIZE_CURRENCY]
  },
  {
    id: SIGNALS_COLS.START_POINT,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.START_POINT]
  },
  {
    id: SIGNALS_COLS.STATE,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.STATE]
  },
  {
    id: SIGNALS_COLS.STATUS,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.STATUS]
  },
  {
    id: SIGNALS_COLS.TIME_PER_SIGNAL,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.TIME_PER_SIGNAL]
  },
  {
    id: SIGNALS_COLS.TIMEFRAME,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.TIMEFRAME]
  },
  {
    id: SIGNALS_COLS.TR_IN_ATR,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.TR_IN_ATR]
  },
  {
    id: SIGNALS_COLS.CANCEL,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.CANCEL]
  },
  {
    id: SIGNALS_COLS.CLOSE_LIMIT,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.CLOSE_LIMIT]
  },
  {
    id: SIGNALS_COLS.CLOSE_MARKET,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.CLOSE_MARKET]
  },
  {
    id: SIGNALS_COLS.PROFILE_ID,
    label: SIGNALS_TABLE_COL_LABELS[SIGNALS_COLS.PROFILE_ID]
  }
];

export const APA_FIELDS_PICK = [
  {
    id: APAColIds.CREATED_AT,
    label: APA_TABLE_COL_NAMES[APAColIds.CREATED_AT]
  },
  {
    id: APAColIds.PROFILE,
    label: APA_TABLE_COL_NAMES[APAColIds.PROFILE]
  },
  {
    id: APAColIds.ID,
    label: APA_TABLE_COL_NAMES[APAColIds.ID]
  },
  {
    id: APAColIds.TICKER,
    label: APA_TABLE_COL_NAMES[APAColIds.TICKER]
  },
  {
    id: APAColIds.ACTIVE_P_SIZE_USD,
    label: APA_TABLE_COL_NAMES[APAColIds.ACTIVE_P_SIZE_USD]
  },
  {
    id: APAColIds.ACTIVE_P_COINS,
    label: APA_TABLE_COL_NAMES[APAColIds.ACTIVE_P_COINS]
  },
  {
    id: APAColIds.R_PLN,
    label: APA_TABLE_COL_NAMES[APAColIds.R_PLN]
  },
  {
    id: APAColIds.U_PLN,
    label: APA_TABLE_COL_NAMES[APAColIds.U_PLN]
  },
  {
    id: APAColIds.AVG_E_PRICE,
    label: APA_TABLE_COL_NAMES[APAColIds.AVG_E_PRICE]
  },
  {
    id: APAColIds.K_EXIT,
    label: APA_TABLE_COL_NAMES[APAColIds.K_EXIT]
  },
  {
    id: APAColIds.CURRENT_PRICE,
    label: APA_TABLE_COL_NAMES[APAColIds.CURRENT_PRICE]
  },
  {
    id: APAColIds.MARK_PRICE,
    label: APA_TABLE_COL_NAMES[APAColIds.MARK_PRICE]
  },
  {
    id: APAColIds.K_EXIT_COEFF,
    label: APA_TABLE_COL_NAMES[APAColIds.K_EXIT_COEFF]
  },
  {
    id: APAColIds.CLOSE_MARKET,
    label: APA_TABLE_COL_NAMES[APAColIds.CLOSE_MARKET]
  },
  {
    id: APAColIds.CLOSE_LIMIT,
    label: APA_TABLE_COL_NAMES[APAColIds.CLOSE_LIMIT]
  },
  {
    id: APAColIds.REDUCE_P,
    label: APA_TABLE_COL_NAMES[APAColIds.REDUCE_P]
  },
  {
    id: APAColIds.KE_RECOVERY,
    label: APA_TABLE_COL_NAMES[APAColIds.KE_RECOVERY]
  },
  {
    id: APAColIds.STATE,
    label: APA_TABLE_COL_NAMES[APAColIds.STATE]
  },
  {
    id: APAColIds.PROFILE_LIST,
    label: APA_TABLE_COL_NAMES[APAColIds.PROFILE_LIST]
  }
];

export const CALS_MAP = {
  [PrivilegesGroupFields.SIGNALS]: SIGNALS_FIELDS_PICK,
  [PrivilegesGroupFields.LOGS]: LOGS_FIELDS_PICK,
  [PrivilegesGroupFields.PROFILES]: PROFILES_FIELDS_PICK,
  [PrivilegesGroupFields.ORDERS]: ORDERS_FIELDS_PICK,
  [PrivilegesGroupFields.MM_PROFILES]: MM_PROFILES_FIELDS_PICK,
  [PrivilegesGroupFields.PROFILES_STATS]: PROFILES_STATS_FIELDS_PICK,
  [PrivilegesGroupFields.APA]: APA_FIELDS_PICK
};
