import { call, put, takeEvery, ForkEffect } from 'redux-saga/effects';
import { mmProfilesTypes } from '../types';
import * as apiHelpers from './apiHelpers/mmProfilesApiHelpers';
import * as actions from '../actions/mmProfilesActions';
import * as Shapes from 'interfaces/mmProfilesShapes';
import { callbackResolver } from 'helpers/callbackHelpers';
import { GetAutoDepositMMProfile, GetDepositInfoMMProfile, MMStatuses } from 'interfaces/mmProfilesShapes';
import { putDepositInfoMMProfile, putMMProfiles, saveAutoDepositMMProfile } from '../actions/mmProfilesActions';
import { getDepositInfoMMProfileHelper } from './apiHelpers/mmProfilesApiHelpers';

function* addMMProfileWorker({ payload }: Shapes.AddMMProfileShape) {
  try {
    yield call(apiHelpers.addMMProfileHelper, payload.reqData);
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('addMMProfileWorker', error);
  }
}

function* getMMProfilesWorker({ payload }: Shapes.GetMMProfilesShape) {
  try {
    const { data } = yield call(apiHelpers.getMMProfilesHelper, payload.reqParams);
    yield put(actions.putMMProfiles(data));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('getMMProfilesWorker', error);
  }
}

function* changeMMProfileWorker({ payload }: Shapes.ChangeMMProfile) {
  try {
    const { data } = yield call(apiHelpers.changeMMProfileHelper, payload.profileId, payload.reqData);
    yield put(actions.changeMMProfileSuccess(data));
    callbackResolver(payload?.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('changeMMProfileWorker', error);
  }
}

function* getMMProfileWorker({ payload }: Shapes.GetMMProfile) {
  try {
    const { data } = yield call(apiHelpers.getMMProfileHelper, payload.id);
    if (data.status === MMStatuses.INACTIVE) {
      yield put(actions.putMMProfile([data]));
    } else {
      payload.onError();
    }
  } catch (error) {
    console.error('getMMProfileWorker', error);
  }
}

function* changeMMProfileVisibilityWorker({ payload }: Shapes.ChangeMMProfileVisibility) {
  try {
    yield call(apiHelpers.changeMMProfileHelper, payload.profileId, payload.reqData);
    callbackResolver(payload.onSuccess);
  } catch (error) {
    console.error('changeMMProfileVisibilityWorker', error);
  }
}

function* changeDepositMMProfileWorker({ payload }: Shapes.ChangeDepositMMProfile) {
  try {
    yield call(apiHelpers.changeDepositMMProfileHelper, payload.reqData);
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('changeDepositMMProfileWorker', error);
  }
}

function* getAutoDepositMMProfileWorker({ payload }: GetAutoDepositMMProfile) {
  try {
    const { data } = yield call(apiHelpers.getAutoDepositMMProfileHelper);
    yield put(saveAutoDepositMMProfile(data));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('getAutoDepositMMProfileWorker', error);
  }
}

function* changeAutoDepositMMProfileWorker({ payload }: Shapes.ChangeAutoDepositMMProfile) {
  try {
    const { data } = yield call(apiHelpers.changeAutoDepositMMProfileHelper, payload.reqData);
    yield put(saveAutoDepositMMProfile(data));
    callbackResolver(payload.onSuccess);
  } catch (error) {
    payload.onError(error);
    console.error('changeAutoDepositMMProfileWorker', error);
  }
}

function* getDepositInfoMMProfileWorker({ payload }: Shapes.GetDepositInfoMMProfile) {
  try {
    const { data } = yield call(apiHelpers.getDepositInfoMMProfileHelper);
    yield put(putDepositInfoMMProfile(data));
  } catch (error) {
    payload.onError(error);
    console.error('getDepositInfoMMProfileWorker', error);
  }
}

export function* mmProfilesWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeEvery(mmProfilesTypes.ADD_MM_PROFILE, addMMProfileWorker);
  yield takeEvery(mmProfilesTypes.GET_MM_PROFILES, getMMProfilesWorker);
  yield takeEvery(mmProfilesTypes.CHANGE_MM_PROFILE, changeMMProfileWorker);
  yield takeEvery(mmProfilesTypes.GET_MM_PROFILE, getMMProfileWorker);
  yield takeEvery(mmProfilesTypes.CHANGE_MM_PROFILE_VISIBILITY, changeMMProfileVisibilityWorker);
  yield takeEvery(mmProfilesTypes.CHANGE_DEPOSIT_MM_PROFILE, changeDepositMMProfileWorker);
  yield takeEvery(mmProfilesTypes.GET_AUTO_DEPOSIT_MM_PROFILE, getAutoDepositMMProfileWorker);
  yield takeEvery(mmProfilesTypes.CHANGE_AUTO_DEPOSIT_MM_PROFILE, changeAutoDepositMMProfileWorker);
  yield takeEvery(mmProfilesTypes.GET_DEPOSIT_INFO_MM_PROFILE, getDepositInfoMMProfileWorker);
}
