import { ColumnAlignOptions } from 'constants/randomConstants';

export enum EditTypes {
  PROFILE = 'PROFILE',
  PARAMETERS = 'PARAMETERS',
  ORDER_MANAGEMENT = 'ORDER_MANAGEMENT'
}

export enum SummaryOrdersTableColIds {
  ORDER = 'order',
  DISTANCE = 'distance',
  SIZE = 'size',
  TIMER = 'timer'
}

export enum SummaryTimeExitTableColIds {
  EXIT_BY_TIME = 'exit_by_time',
  EXIT_TIMER = 'exit_timer_minutes',
  TIME_COEFFICIENT = 'coefficient'
}

export enum SummaryDistanceExitTableColIds {
  EXIT_BY_DISTANCE = 'exit_by_distance',
  EXIT_THRESHOLD = 'exit_d_threshold',
  DISTANCE_COEFFICIENT = 'coefficient'
}

export interface SummaryOrdersTRow {
  id: string | number;
  [SummaryOrdersTableColIds.ORDER]: string;
  [SummaryOrdersTableColIds.DISTANCE]: string;
  [SummaryOrdersTableColIds.SIZE]: string;
  [SummaryOrdersTableColIds.TIMER]: string;
}

export interface SummaryTimeExitsTRow {
  id: string;
  [SummaryTimeExitTableColIds.EXIT_BY_TIME]: string;
  [SummaryTimeExitTableColIds.EXIT_TIMER]: string;
  [SummaryTimeExitTableColIds.TIME_COEFFICIENT]: string;
}

export interface SummaryDistanceExitsTRow {
  id: string;
  [SummaryDistanceExitTableColIds.EXIT_BY_DISTANCE]: string;
  [SummaryDistanceExitTableColIds.EXIT_THRESHOLD]: string;
  [SummaryDistanceExitTableColIds.DISTANCE_COEFFICIENT]: string;
}

export type OrdersColumnsType = typeof ORDER_COLUMNS | typeof EXIT_TIME_COLUMNS | typeof EXIT_DISTANCE_COLUMNS;

export const ORDER_COLUMNS = [
  {
    id: SummaryOrdersTableColIds.ORDER,
    title: '',
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SummaryOrdersTableColIds.DISTANCE,
    title: 'Distance (in ATR)',
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SummaryOrdersTableColIds.SIZE,
    title: 'Size (%)',
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SummaryOrdersTableColIds.TIMER,
    title: 'ROC timer (min)',
    align: ColumnAlignOptions.CENTER
  }
];

export const EXIT_TIME_COLUMNS = [
  {
    id: SummaryTimeExitTableColIds.EXIT_BY_TIME,
    title: '',
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SummaryTimeExitTableColIds.EXIT_TIMER,
    title: 'Exit timer',
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SummaryTimeExitTableColIds.TIME_COEFFICIENT,
    title: 'Exit timer coefficient',
    align: ColumnAlignOptions.CENTER
  }
];

export const EXIT_DISTANCE_COLUMNS = [
  {
    id: SummaryDistanceExitTableColIds.EXIT_BY_DISTANCE,
    title: '',
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SummaryDistanceExitTableColIds.EXIT_THRESHOLD,
    title: 'Exit threshold',
    align: ColumnAlignOptions.CENTER
  },
  {
    id: SummaryDistanceExitTableColIds.DISTANCE_COEFFICIENT,
    title: 'Exit distance coefficient',
    align: ColumnAlignOptions.CENTER
  }
];
