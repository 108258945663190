import React, { ReactNode } from 'react';
import cn from 'classnames';
import Button from '@material-ui/core/Button';
import * as Shapes from 'interfaces/signalsOrdersShapes';
import {
  ACTIVE_ORDER_STATUSES,
  AdministrationColIds,
  ChangeableTriggerProperty,
  MODAL_TITLE_MAP,
  OrdersColIds,
  RiskControlColIds,
  SIGNAL_FILTERS,
  SignalActionStates,
  SignalsColIds,
  SignalsFilterIDs,
  SignalStateTypes,
  TriggerEnum,
  VALIDATION_BLOCKER_MAP
} from 'constants/signalsOrdersConstants';
import { ButtonColorTypes } from 'constants/randomConstants';
import { firstLetterUppercase, formatISODate } from 'helpers/randomHelpers';
import s from 'components/signalsTable/SignalsTable.module.scss';
import { TableColumn } from '../interfaces/randomShapes';
import Check from '@material-ui/icons/Check';
import Cancel from '@material-ui/icons/Cancel';
import ProfileMultiselect from '../components/riskControlTable/profileMultiselect/ProfileMultiselect';
import { isNull } from 'lodash';
import { Checkbox, FormControl } from '@material-ui/core';
import * as Yup from 'yup';
import { useSelector } from 'react-redux';
import { riskControlObjectSelector } from '../redux/selectors';
import { StrategyColIds } from '../constants/strategyProfilesConstants';
import { FormKeys, FormValues, TriggerTypes } from '../constants/riskControlConstants';
import { CustomCheckbox } from '../components/ui/CustomCheckbox';
import { Link } from 'react-router-dom';
import { PAGES } from '../constants/routes';
import { usePrivileges } from 'hooks/usePrivileges';

export const createOrdersRow = ({
  id,
  created_at,
  signal,
  profile,
  pair,
  direction,
  position_direction,
  exchange_order_id,
  price,
  quantity,
  status
}: Shapes.OrderShape): Shapes.OrderTableRow => {
  const formattedDate = formatISODate(created_at);
  const orderSize = (+price * +quantity).toFixed(2);
  const trimmedPrice = (+price).toFixed(5);
  const trimmedQuantity = (+quantity).toFixed(7);
  return {
    id,
    created_at: formattedDate,
    signal,
    profile,
    pair,
    direction,
    exchange_order_id,
    order_type: position_direction,
    price: trimmedPrice,
    o_size: orderSize,
    quantity: trimmedQuantity,
    status,
    action: 'Cancel'
  };
};

export const ordersPageRenderSwitch = (
  colId: OrdersColIds,
  row: Shapes.OrderTableRow,
  onCancelOrder: (id: number) => void
): ReactNode => {
  const value = row[colId === OrdersColIds.ORDER_ID ? OrdersColIds.ID : colId] || 'exec_type';
  const isActive = colId === OrdersColIds.ACTION && ACTIVE_ORDER_STATUSES.includes(row.status);

  switch (colId) {
    case OrdersColIds.ORDER_STATE:
      return (
        <span
          style={{
            color: !ACTIVE_ORDER_STATUSES.includes(row.status) ? '#e0391c' : '#4caf50'
          }}
        >
          {firstLetterUppercase(value)}
        </span>
      );

    case OrdersColIds.ACTION:
      return (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          disabled={!isActive}
          color={!isActive ? ButtonColorTypes.DEFAULT : ButtonColorTypes.SECONDARY}
          onClick={() => onCancelOrder(row.id)}
        >
          {value}
        </Button>
      );

    default:
      return <>{value ?? '-'}</>;
  }
};

export const createSignalsRow = ({
  id,
  state,
  status,
  direction,
  created_at,
  profile,
  pair,
  execution,
  timeframe,
  size_coins,
  size_currency,
  avg_e_price,
  exit_place,
  current_price,
  pnl_in_usd,
  pnl_in_percent,
  tr_in_atr,
  inc_vola,
  atr_minus_1,
  minute_activated_at,
  time_per_signal,
  action_state,
  atr_t_percent,
  profile_id
}: Shapes.SignalShape): Shapes.SignalTableRow => {
  const formattedDate = formatISODate(created_at, 'dd.MM.yyyy HH:mm:ss');
  return {
    created_at: formattedDate,
    id,
    state,
    status,
    direction,
    profile,
    pair,
    execution,
    timeframe,
    size_coins,
    size_currency,
    avg_e_price,
    exit_place: (+exit_place).toFixed(5),
    current_price: (+current_price).toFixed(5),
    pnl_in_usd,
    pnl_in_percent,
    tr_in_atr,
    inc_vola,
    atr_minus_1,
    minute_activated_at,
    time_per_signal,
    cancel: 'Cancel',
    action_state: action_state ?? '-',
    atr_t_percent,
    profile_id: profile_id ?? null
  };
};

const GREEN_ACTIVE_STATES = [SignalActionStates.NONE];
const RED_ACTIVE_STATES = [
  SignalActionStates.CANCELLING,
  SignalActionStates.CANCELLED,
  SignalActionStates.CLOSING,
  SignalActionStates.CLOSED
];

export const signalsRenderSwitch = (
  colId: SignalsColIds,
  row: Shapes.SignalTableRow,
  isLimited: boolean,
  onAction: (colId: SignalsColIds, id: number) => void,
  access: { logPage: boolean }
): JSX.Element => {
  const value = row[colId];

  switch (colId) {
    case SignalsColIds.CANCEL:
      return (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          disabled={row.state !== SignalStateTypes.ACTIVE_NP}
          color={row.state !== SignalStateTypes.ACTIVE_NP ? ButtonColorTypes.DEFAULT : ButtonColorTypes.SECONDARY}
          onClick={() => onAction(colId, row.id)}
          classes={{ root: s.btnRoot }}
        >
          {value}
        </Button>
      );

    case SignalsColIds.ACTION_STATE:
      return (
        <span
          className={cn(s.statusDefault, {
            [s.statusActive]: GREEN_ACTIVE_STATES.includes(row.action_state as SignalActionStates),
            [s.statusInactive]: RED_ACTIVE_STATES.includes(row.action_state as SignalActionStates)
          })}
        >
          {firstLetterUppercase(value)}
        </span>
      );

    case SignalsColIds.STRATEGY_PROFILE:
      if (isLimited && row[SignalsColIds.STRATEGY_PROFILE_ID]) {
        return <>{row[SignalsColIds.STRATEGY_PROFILE_ID]}</>;
      }
      return <>{value ?? '-'}</>;

    case SignalsColIds.SIGNAL_ID:
      if (!value) {
        return <>-</>;
      }
      return access.logPage ? (
        <Link to={{ pathname: PAGES.LOGS, search: `?signal_id=${value}` }}>{value}</Link>
      ) : (
        <>{value}</>
      );

    default:
      return <>{value ?? '-'}</>;
  }
};

export const customizeFilter = (
  filters: Shapes.Profiles[],
  pairs: Shapes.Profiles[],
  customFilter: typeof SIGNAL_FILTERS
): typeof SIGNAL_FILTERS => {
  const getFilterOptions = (elem: Shapes.Profiles) => ({
    value: String(elem?.id),
    label: String(elem?.title)
  });
  return customFilter.map((filter) => {
    switch (true) {
      case filter.id === SignalsFilterIDs.PROFILE:
        return { ...filter, options: [...filter.options, ...filters.map(getFilterOptions)] };
      case filter.id === SignalsFilterIDs.PAIRS:
        return { ...filter, options: [...filter.options, ...pairs.map(getFilterOptions)] };
      default:
        return filter;
    }
  });
};

export const hideColumnsStrategy = (columns: TableColumn[], columnsToHide: string[]): TableColumn[] => {
  return columns.filter((col) => columnsToHide.indexOf(col.id) < 0);
};

export const createAdministrationRow = ({
  created_at,
  avg_e_price,
  k_exit,
  active_p_size_usd,
  r_pnl,
  k_exit_coeff,
  u_pnl,
  ticker,
  profile,
  active_p_coins,
  id,
  mark_price,
  current_price,
  side = null,
  state,
  skip_check_by_timer,
  skip_check_by_distance
}: Shapes.AdminTableRow): Shapes.AdminTableShape => {
  const formattedDate = formatISODate(created_at, 'dd.MM.yyyy HH:mm:ss');
  const isLostPosition = state === SignalStateTypes.EMPTY;
  return {
    created_at: formattedDate,
    id,
    active_p_coins,
    active_p_size_usd,
    r_pnl,
    k_exit,
    avg_e_price,
    k_exit_coeff,
    profile,
    u_pnl,
    ticker,
    mark_price,
    current_price,
    state,
    side,
    isLostPosition,
    close_market: 'Close',
    close_limit: 'Close',
    action: ['Cancel', 'Action'],
    skip_check_by_timer,
    skip_check_by_distance
  };
};

export const adminPageRenderSwitch = (
  colId: AdministrationColIds,
  row: Shapes.AdminTableShape,
  onAction: (colId: AdministrationColIds, id: number) => void,
  onCloseBy: (colId: AdministrationColIds, id: number) => void,
  canSkipKexit: boolean,
  onSkipByTimer: (id: number, state: boolean) => void,
  onSkipByDistance: (id: number, state: boolean) => void,
  onChangePicker: (id: number, isChecked: boolean) => void,
  pickerValue: boolean,
  pickers: { [id: number]: boolean }
): ReactNode => {
  const value = row[colId];
  const isDisabledCloseByLimit = +row.active_p_coins === 0;
  const isLostPosition = row.isLostPosition;

  switch (colId) {
    case AdministrationColIds.PICKER:
      return (
        <CustomCheckbox value={pickerValue} onChange={(e) => onChangePicker(row.id, e.target.checked)} name="picker" />
      );

    case AdministrationColIds.REDUCE_P:
      if (isLostPosition) {
        return <></>;
      }
      return (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          color={ButtonColorTypes.DEFAULT}
          onClick={() => onAction(colId, row.id)}
          disabled={Boolean(pickers[row.id])}
        >
          START
        </Button>
      );

    case AdministrationColIds.KE_RECOVERY:
      if (isLostPosition) {
        return <></>;
      }
      return (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          color={ButtonColorTypes.SECONDARY}
          onClick={() => onAction(colId, row.id)}
          disabled={Boolean(pickers[row.id])}
        >
          START
        </Button>
      );

    case AdministrationColIds.CLOSE_LIMIT:
      if (isLostPosition) {
        return <></>;
      }
      return (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          disabled={isDisabledCloseByLimit || Boolean(pickers[row.id])}
          color={isDisabledCloseByLimit ? ButtonColorTypes.SECONDARY : ButtonColorTypes.DEFAULT}
          onClick={() => onCloseBy(colId, row.id)}
          classes={{ root: s.btnRoot }}
        >
          {value}
        </Button>
      );

    case AdministrationColIds.CLOSE_MARKET:
      return (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          color={ButtonColorTypes.DEFAULT}
          onClick={() => onCloseBy(colId, row.id)}
          classes={{ root: s.btnRoot }}
          disabled={Boolean(pickers[row.id])}
        >
          {value}
        </Button>
      );

    case AdministrationColIds.SKIP_CHECK_BY_TIMER:
      return (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          color={value ? ButtonColorTypes.SECONDARY : ButtonColorTypes.DEFAULT}
          onClick={() => onSkipByTimer(row.id, value)}
          classes={{ root: s.btnRoot }}
          disabled={canSkipKexit && Boolean(pickers[row.id])}
        >
          {value ? 'Disable' : 'Enable'}
        </Button>
      );

    case AdministrationColIds.SKIP_CHECK_BY_DISTANCE:
      return (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="button"
          color={value ? ButtonColorTypes.SECONDARY : ButtonColorTypes.DEFAULT}
          onClick={() => onSkipByDistance(row.id, value)}
          classes={{ root: s.btnRoot }}
          disabled={canSkipKexit && Boolean(pickers[row.id])}
        >
          {value ? 'Disable' : 'Enable'}
        </Button>
      );

    case AdministrationColIds.SIGNAL_ID:
      return isLostPosition ? (
        <>-</>
      ) : (
        <a href={value ? `/logs?page=1&page_size=500&signal_id=${value}` : '#'}>{value ?? '-'}</a>
      );

    case AdministrationColIds.ACTIVE_P_COINS:
      return <>{Number(value)?.toFixed(4) ?? '-'}</>;

    case AdministrationColIds.K_EXIT:
      return <>{isLostPosition ? '-' : Number(value)?.toFixed(7) ?? '-'}</>;

    case AdministrationColIds.MARK_PRICE:
      return <>{Number(value)?.toFixed(5) ?? '-'}</>;

    default:
      return <>{value ?? '-'}</>;
  }
};

export const createRiskControlRow = (data: Shapes.RiskControlShape, index: number): Shapes.RiskControlTableShape => {
  return {
    ...data,
    index: index + 1,
    action: ['Save']
  };
};

export const riskControlRenderSwitch = (
  colId: RiskControlColIds,
  row: Shapes.RiskControlTableShape,
  onAction: (id: number, changingProperty: ChangeableTriggerProperty) => void,
  onChangeProperty: (id: number, valueToChange: number | boolean, property: ChangeableTriggerProperty) => void,
  onSave: (id: number) => void
): ReactNode => {
  const value = row[colId];
  switch (colId) {
    case RiskControlColIds.ID:
      return <>#{row.index}</>;

    case RiskControlColIds.TRIGGER_BLOCKER:
      if (row.trigger_type === 'nav_reducing') {
        return (
          <div className={s.opposite_dir}>
            <span className={s.blockerLink} onClick={() => onAction(row.id, ChangeableTriggerProperty.TR_BLOCK)}>
              NAV.trig={row.trigger_blocker}
            </span>
            <span className={s.blockerLink} onClick={() => onAction(row.id, ChangeableTriggerProperty.POSITION_REDUCE)}>
              P-red%={row.position_reduce}
            </span>
            <span className={s.blockerLink} onClick={() => onAction(row.id, ChangeableTriggerProperty.X_PERCENT_DEPO)}>
              x%depo={row.x_percent_depo}
            </span>
            <span className={s.blockerLink} onClick={() => onAction(row.id, ChangeableTriggerProperty.Y_PERCENT_DEPO)}>
              y%depo={row.y_percent_depo}
            </span>
          </div>
        );
      }
      if (!isNull(value)) {
        return (
          <div onClick={() => onAction(row.id, ChangeableTriggerProperty.TR_BLOCK)} className={s.blockerLink}>
            {value}
          </div>
        );
      }
      if (row.trigger_type === 'opposite_dir') {
        return (
          <div className={s.opposite_dir}>
            <span className={s.blockerLink} onClick={() => onAction(row.id, ChangeableTriggerProperty.SP)}>
              SP={row.trigger_blocker_opp_dir_sp}
            </span>
            <span className={s.blockerLink} onClick={() => onAction(row.id, ChangeableTriggerProperty.ATR)}>
              ATR-T%={row.trigger_blocker_opp_dir_atr}
            </span>
          </div>
        );
      }
      return <ProfileMultiselect triggerId={row.id} isGroupCol={false} onChangeSelect={onChangeProperty} />;

    case RiskControlColIds.TIMER:
      if (!isNull(value)) {
        return (
          <div onClick={() => onAction(row.id, ChangeableTriggerProperty.TIMER)} className={s.blockerLink}>
            {value}
          </div>
        );
      }
      return <>-</>;

    case RiskControlColIds.TRIGGER_UNBLOCKER:
      if (!isNull(value)) {
        return (
          <div onClick={() => onAction(row.id, ChangeableTriggerProperty.TR_UNBLOCK)} className={s.blockerLink}>
            {value}
          </div>
        );
      }
      return <>-</>;

    case RiskControlColIds.ACCOUNT:
      return <>{value ?? ''}</>;

    case RiskControlColIds.ALERT:
      return value ? <Check /> : <Cancel />;

    case RiskControlColIds.GROUP_PROFILES:
      return <ProfileMultiselect triggerId={row.id} isGroupCol onChangeSelect={onChangeProperty} />;

    case RiskControlColIds.ACTIVE:
      return (
        <FormControl>
          <Checkbox
            color="primary"
            style={{ transform: 'scale(1.6)' }}
            value={row.is_active}
            checked={row.is_active}
            name={RiskControlColIds.ACTIVE}
            onChange={() => onChangeProperty(row.id, !row.is_active, ChangeableTriggerProperty.ACTIVE)}
          />
        </FormControl>
      );

    case RiskControlColIds.ACTION:
      return (
        <Button
          fullWidth
          variant="outlined"
          size="medium"
          type="submit"
          color="primary"
          classes={{ root: s.btn }}
          onClick={() => onSave(row.id)}
        >
          Save
        </Button>
      );

    case RiskControlColIds.VISIBILITY:
      return (
        <FormControl>
          <Checkbox
            color="primary"
            style={{ transform: 'scale(1.6)' }}
            value={row.is_show}
            checked={row.is_show}
            name={StrategyColIds.VISIBILITY}
            onChange={() => onChangeProperty(row.id, !row.is_show, ChangeableTriggerProperty.VISIBILITY)}
          />
        </FormControl>
      );

    default:
      return <>{value ?? '-'}</>;
  }
};

export const getInitValue = (
  picker: ChangeableTriggerProperty,
  trigger_blocker: number,
  trigger_unblocker: number,
  trigger_blocker_opp_dir_sp: number,
  trigger_blocker_opp_dir_atr: number,
  position_reduce: number,
  x_percent_depo: number,
  y_percent_depo: number,
  timer: number
): number => {
  const initOptions = {
    trigger_blocker,
    trigger_unblocker,
    trigger_blocker_opp_dir_sp,
    trigger_blocker_opp_dir_atr,
    position_reduce,
    x_percent_depo,
    y_percent_depo,
    timer
  };
  return initOptions[picker] ?? 0;
};

export const getValidationSchema = (trigger_type: string): ReturnType<typeof Yup.object> => {
  return Yup.object({
    trigger_blocker: Yup.number()
      .min(VALIDATION_BLOCKER_MAP[trigger_type].min, VALIDATION_BLOCKER_MAP[trigger_type].description)
      .max(VALIDATION_BLOCKER_MAP[trigger_type].max, VALIDATION_BLOCKER_MAP[trigger_type].description)
  });
};

export const getModalTitle = (changingProperty: string): string => MODAL_TITLE_MAP[changingProperty];

export const isNegativeNumbersAllowed = (trigger_type: string): boolean => trigger_type === TriggerEnum.IMP_LOSS;

interface UseBlockerForm {
  initValue: number;
  modalTitle: string;
  isNegativeAllowed: boolean;
  validationSchema: ReturnType<typeof Yup.object>;
}

export const useBlockerForm = (triggerId: number, changingProperty: ChangeableTriggerProperty): UseBlockerForm => {
  const riskControl = useSelector(riskControlObjectSelector(triggerId));
  const {
    trigger_unblocker = '0' as string,
    trigger_type = '0' as string,
    trigger_blocker = '0' as string,
    trigger_blocker_opp_dir_sp = '0' as string,
    trigger_blocker_opp_dir_atr = '0' as string,
    position_reduce = '0' as string,
    x_percent_depo = '0' as string,
    y_percent_depo = '0' as string,
    timer = '0' as string
  } = riskControl || {};

  const validationSchema = getValidationSchema(changingProperty === 'timer' ? 'timer' : trigger_type);
  const isNegativeAllowed = isNegativeNumbersAllowed(trigger_type);

  const initValue = getInitValue(
    changingProperty,
    +trigger_blocker,
    +trigger_unblocker,
    +trigger_blocker_opp_dir_sp,
    +trigger_blocker_opp_dir_atr,
    +position_reduce,
    +x_percent_depo,
    +y_percent_depo,
    +timer
  );
  const modalTitle = getModalTitle(changingProperty);

  return {
    initValue,
    modalTitle,
    isNegativeAllowed,
    validationSchema
  };
};

export interface ReqValues {
  [FormKeys.TRIGGER_TYPE]: string;
  [FormKeys.TIMER]: number;
  [FormKeys.TRIGGER_BLOCKER]: number;
  [FormKeys.POSITION_REDUCE]: number;
  [FormKeys.X_PERCENT_DEPO]: number;
  [FormKeys.Y_PERCENT_DEPO]: number;
  is_active: boolean;
}

export const getTriggerFormValues = (values: FormValues): ReqValues => {
  return {
    is_active: false,
    [FormKeys.TRIGGER_TYPE]: values[FormKeys.TRIGGER_TYPE],
    [FormKeys.TRIGGER_BLOCKER]: +values[FormKeys.TRIGGER_BLOCKER],
    [FormKeys.TIMER]: +values[FormKeys.TIMER],
    ...(values[FormKeys.TRIGGER_TYPE] === TriggerTypes.NAV_REDUCING && {
      [FormKeys.POSITION_REDUCE]: +values[FormKeys.POSITION_REDUCE],
      [FormKeys.X_PERCENT_DEPO]: +values[FormKeys.X_PERCENT_DEPO],
      [FormKeys.Y_PERCENT_DEPO]: +values[FormKeys.Y_PERCENT_DEPO]
    })
  };
};
