import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import NavTabs from '../navTabs/NavTabs';
import { usePrivileges } from '../../hooks/usePrivileges';
import { getStatsTabs } from '../../helpers/privilegesHelpers';
import s from './StatsStaticGraphs.module.scss';
import { getStatsGraph } from '../../redux/actions/statsActions';
import { useDispatch, useSelector } from 'react-redux';
import { statsChartsSelector } from '../../redux/selectors';
import { getChartData, getInitialFiltersValues, prepareDataTransferObject } from '../../helpers/chartsHelpers';
import {
  CHART_STATS_FILTERS_SECOND,
  ChartsSelectEnum,
  ChartStatsFilterIDs,
  DEFAULT_CHART_IFRAME_PARAMS,
  DEFAULT_CHART_IFRAME_PARAMS_VAR,
  DEFAULT_CHART_PARAMS,
  DEFAULT_CHARTS_FILTERS,
  DefaultFilters,
  FilterChartTypeValues,
  FilterChartValues,
  SeparationValues
} from '../../constants/chartStatsConstants';
import CustomizedChart from '../customizedChart/CustomizedChart';
import ChartsFiltration from './ChartsFiltration/ChartsFiltration';
import { useQueryParams } from '../../hooks/useQueryParams';
import cn from 'classnames';

interface StatsStaticGraphsProps {
  isIframe?: boolean;
}

const StatsStaticGraphs: FC<StatsStaticGraphsProps> = ({ isIframe = false }) => {
  const privileges = usePrivileges();
  const tabs = getStatsTabs(privileges);
  const { queryParams, setNewQueryParams } = useQueryParams(
    isIframe ? DEFAULT_CHART_IFRAME_PARAMS : DEFAULT_CHART_PARAMS
  );

  const dispatch = useDispatch();
  const chartWrapperRef = useRef(null);
  const chartData = useSelector(statsChartsSelector);

  const [chartsCount, setChartsCount] = useState(
    queryParams?.[ChartStatsFilterIDs.CHART_TYPE_2] ? ChartsSelectEnum.Two : ChartsSelectEnum.One
  );
  const [reqParams, setReqParams] = useState(getInitialFiltersValues(queryParams));
  const series = useMemo(() => getChartData(chartData, reqParams), [chartData, reqParams]);

  const [chartsFilters, setChartsFilters] = useState<DefaultFilters>(
    queryParams?.[ChartStatsFilterIDs.CHART_TYPE_2]
      ? [...DEFAULT_CHARTS_FILTERS, ...CHART_STATS_FILTERS_SECOND]
      : DEFAULT_CHARTS_FILTERS
  );

  const [filterParams, setFilterParams] = useState(getInitialFiltersValues(queryParams));
  const [disabledFilter, setDisabledFilter] = useState(true);

  const handleFilterChange = useCallback(
    (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
      const name = event.target.name as keyof typeof filterParams;
      const updatedFilterParams = {
        ...filterParams,
        [name]:
          name === ChartStatsFilterIDs.SEPARATED
            ? (event as React.ChangeEvent<HTMLInputElement>).target.checked.toString()
            : (event.target.value as string)
      };
      setFilterParams(updatedFilterParams);
      setDisabledFilter(JSON.stringify(updatedFilterParams) === JSON.stringify(reqParams));
    },
    [filterParams, reqParams]
  );

  useEffect(() => {
    dispatch(getStatsGraph(prepareDataTransferObject(reqParams)));
    setNewQueryParams(reqParams);
  }, [dispatch, reqParams, setNewQueryParams]);

  const onSelectChartsAmount = useCallback(
    (amountOfCharts: ChartsSelectEnum) => {
      if (+chartsCount < +amountOfCharts) {
        const updatedFilterParams = {
          ...filterParams,
          [ChartStatsFilterIDs.CHART_TYPE_2]: FilterChartValues.CANDLES,
          [ChartStatsFilterIDs.DATA_TYPE_2]: FilterChartTypeValues.NAV
        };
        setChartsFilters((prev) => [...prev, ...CHART_STATS_FILTERS_SECOND]);
        setFilterParams(updatedFilterParams);
        setDisabledFilter(JSON.stringify(updatedFilterParams) === JSON.stringify(reqParams));
      } else {
        const updatedFilterParams = {
          ...filterParams,
          [ChartStatsFilterIDs.CHART_TYPE_2]: null,
          [ChartStatsFilterIDs.DATA_TYPE_2]: null,
          [ChartStatsFilterIDs.SEPARATED]: SeparationValues.unseparated
        };
        setChartsFilters((prev) => [prev[0], prev[1]]);
        setFilterParams(updatedFilterParams);
        setDisabledFilter(JSON.stringify(updatedFilterParams) === JSON.stringify(reqParams));
      }
      setChartsCount(amountOfCharts as ChartsSelectEnum);
    },
    [chartsCount, filterParams, reqParams]
  );

  const onClearFilters = useCallback(() => {
    setReqParams(DEFAULT_CHART_PARAMS);
    setFilterParams(DEFAULT_CHART_PARAMS);
    setChartsCount(ChartsSelectEnum.One);
    setChartsFilters(DEFAULT_CHARTS_FILTERS);
  }, []);

  const onChangeChart = useCallback((chartTitle: FilterChartTypeValues) => {
    setReqParams(
      chartTitle === FilterChartTypeValues.EQUITY ? DEFAULT_CHART_IFRAME_PARAMS : DEFAULT_CHART_IFRAME_PARAMS_VAR
    );
  }, []);

  return (
    <div className={s.staticsPage}>
      <div className={s.headerWrapper} style={isIframe ? { display: 'none' } : {}}>
        <h2 className="default-title">Charts stats</h2>
        <NavTabs tabs={tabs} />
      </div>
      <ChartsFiltration
        handleFilterChange={handleFilterChange}
        filterParams={filterParams}
        disabledFilter={disabledFilter}
        setDisabledFilter={setDisabledFilter}
        setReqParams={setReqParams}
        chartsFilters={chartsFilters}
        chartsCount={chartsCount}
        onSelectChartsAmount={onSelectChartsAmount}
        handleClearFilters={onClearFilters}
        isIframe={isIframe}
      />
      {series?.[0]?.data?.length > 0 ? (
        <div ref={chartWrapperRef} className={cn({ [s.graphWrapper]: !isIframe, [s.graphWrapperIframe]: isIframe })}>
          <CustomizedChart
            chartType={reqParams[ChartStatsFilterIDs.CHART_TYPE]}
            chartType_2={reqParams[ChartStatsFilterIDs.CHART_TYPE_2]}
            series={series}
            parentRef={chartWrapperRef}
            timeFrame={reqParams[ChartStatsFilterIDs.TIME_FRAME]}
            isSeparated={reqParams[ChartStatsFilterIDs.SEPARATED] === SeparationValues.separated}
            chartName={reqParams[ChartStatsFilterIDs.DATA_TYPE]}
            chartName2={reqParams[ChartStatsFilterIDs.DATA_TYPE_2]}
            isIframe={isIframe}
            onChangeChart={onChangeChart}
          />
        </div>
      ) : (
        <div>No Data...</div>
      )}
    </div>
  );
};

export default StatsStaticGraphs;
